<script lang="ts">
  // TODO: TS fix
  import Shell from "$lib/Shell.svelte";
  import * as AlertDialog from "$ui/alert-dialog";

  import { deleteApp, getApps, getPermission } from "$lib/utils/resources";
  import { selectedTeamIdStore } from "$utils/stores";

  import Wizard from "$lib/Wizard.svelte";
  import AppForm from "$lib/AppForm.svelte";
  import DataSourcesForm from "$lib/DataSourcesForm.svelte";
  import IntegrateInstallStep from "$lib/IntegrateInstallStep.svelte";
  import IntegrateFrontendStep from "$lib/IntegrateFrontendStep.svelte";
  import IntegrateBackendStep from "$lib/IntegrateBackendStep.svelte";
  import AppApiKeys from "$lib/AppApiKeys.svelte";

  import * as DropdownMenu from "$ui/dropdown-menu";
  import * as Drawer from "$ui/drawer";
  import { Button } from "$ui/button";
  import { LoadingSpinner } from "$ui/loading-spinner";

  import { SearchBar } from "$ui/search-bar";
  import { fuzzyMatch } from "$utils/helpers";
  import { cn } from "$utils/ui";

  import DotsIcon from "$assets/dots.svelte";
  import AppShapesIcon from "$assets/app-shapes.svelte";
  import EditIcon from "$assets/edit.svelte";
  import InstructionsIcon from "$assets/check-list.svelte";
  import BinIcon from "$assets/bin.svelte";

  import { appKeyStore } from "$utils/stores";

  let drawerOpen = false;
  let onlyInstructions = true;
  let confirmModalOpen = false;
  let selectedApp: { app_id: any; description: any; redirect_uris: any } | null = null;

  let isLoading: boolean = true;
  let apps: Array<{ id: string; [key: string]: any }> = []; // Assuming 'apps' structure for TypeScript
  let appIds: string[] = [];
  let filteredApps = apps;

  let isDeleting = false;
  let errorMessage = "";

  let step = 0;
  $: step;

  $: getApps((newApps) => {
    apps = newApps;
    filteredApps = newApps.sort((a, b) => a.id.localeCompare(b.id));
    isLoading = false;
  });

  $: appIds = apps.map((app) => app.id);

  $: steps = [
    {
      title: "Add team",
      subtitle: "Assign your team an alias and team ID",
      component: AppForm,
      props: {
        appIds,
      },
    },
    {
      title: "Integrate data sources",
      subtitle: "Choose a product to integrate with by following step-by-step instructions.",
      component: DataSourcesForm,
      props: {
        allowSkipToEnd: true,
      },
    },
    {
      title: "Integrate data sources",
      subtitle: "Choose a product to integrate with by following step-by-step instructions.",
      component: IntegrateFrontendStep,
      props: {
        app_id: selectedApp?.app_id,
        hideGenerateKey: selectedApp ? true : false,
        allowSkipToEnd: true,
      },
    },
    {
      title: "Install SDK and initialize client",
      subtitle: "Setup the SDK and connect to our API using your app key.",
      component: IntegrateInstallStep,
      props: {
        app_id: selectedApp?.app_id,
        hideGenerateKey: selectedApp ? true : false,
        allowSkipToEnd: true,
      },
    },
    {
      title: "Integrate data sources",
      subtitle: "Choose a product to integrate with by following step-by-step instructions.",
      component: IntegrateBackendStep,
      props: {
        app_id: selectedApp?.app_id,
        hideGenerateKey: selectedApp ? true : false,
      },
    },
  ];

  const onAddApp = () => {
    selectedApp = null;
    onlyInstructions = false;
    openDrawer();
  };

  const onEditApp = (appId: string) => {
    onlyInstructions = false;
    const app = apps.find((app) => app.id === appId);
    selectedApp = {
      ...app?.data(),
      app_id: app?.id,
    };
    openDrawer();
  };

  const onShowInstructions = (appId: string) => {
    onlyInstructions = true;
    const app = apps.find((app) => app.id === appId);
    selectedApp = {
      ...app?.data(),
      app_id: app?.id,
    };
    // selectedApp = null;
    openDrawer();
  };

  const onDeleteConfirm = (appId: string) => {
    const app = apps.find((app) => app.id === appId);
    selectedApp = {
      ...app?.data(),
      app_id: app?.id,
    };
    confirmModalOpen = true;
  };

  const onDeleteApp = async () => {
    try {
      isDeleting = true;
      await deleteApp(selectedApp?.app_id);
      confirmModalOpen = false;
    } catch (error: any) {
      errorMessage = "Error deleting App. " + error?.message;
      console.error("Error deleting App:", error);
    } finally {
      isDeleting = false;
    }
  };

  const openDrawer = () => {
    drawerOpen = true;
  };

  const closeDrawer = () => {
    drawerOpen = false;
    appKeyStore.set("");
  };

  const handleOpenChange = (isOpen: boolean) => {
    appKeyStore.set("");
  };

  const onSearch = (newTerm: string) => {
    filteredApps =
      newTerm !== ""
        ? apps.filter((app) => {
            const appData = app.data();
            return fuzzyMatch(app.id, newTerm) || fuzzyMatch(appData.description, newTerm);
          })
        : apps;
  };

  const hasPermission = getPermission("apps:write");

  $: drawerTitle = "Create New App";
  $: drawerSubtitle = "Create a new app to start collecting data";

  $: if (selectedApp && !onlyInstructions) {
    drawerTitle = "Edit App";
    drawerSubtitle = "Edit your app details";
  } else if (selectedApp && onlyInstructions) {
    drawerTitle = "Integration Instructions";
    drawerSubtitle = "Follow the instructions to integrate your app with Redactive";
  }
</script>

<Shell title="Apps" class="flex flex-col gap-4 xs:gap-8">
  <div class="flex flex-col-reverse xs:flex-row justify-between xs:items-center gap-4">
    <div class="w-full md:max-w-[300px]">
      <SearchBar onChange={onSearch} />
    </div>
    <Drawer.Root bind:open={drawerOpen} preventScroll={false} closeFocus={null} onOpenChange={handleOpenChange}>
      <Drawer.Trigger disabled={!$hasPermission}>
        <Button on:click={onAddApp} class="w-full min-h-[46px]" disabled={!$hasPermission}>+ Add App</Button>
      </Drawer.Trigger>
      <Drawer.Content>
        <Drawer.Header class="pt-8 px-6 pb-4 relative">
          <Drawer.Title class="text-xl text-left">{drawerTitle}</Drawer.Title>
          <Drawer.Description class="p text-base text-left">{drawerSubtitle}</Drawer.Description>
        </Drawer.Header>
        <div class="px-6 flex-1 flex flex-col overflow-auto">
          {#if selectedApp && !onlyInstructions}
            <AppForm
              initialValues={{
                app_id: selectedApp.app_id,
                description: selectedApp.description,
                // @ts-ignore
                redirect_uris: selectedApp.redirect_uris.map((uri) => ({ uri })),
              }}
              isEdit={true}
              onSuccess={closeDrawer}
            />
          {:else}
            <Wizard
              steps={selectedApp ? steps.slice(1) : steps}
              onFinish={() => closeDrawer()}
              bind:wizardStep={step}
            />
          {/if}
        </div>
      </Drawer.Content>
    </Drawer.Root>
  </div>

  {#if isLoading}
    <div class="flex justify-center items-center">
      <LoadingSpinner />
    </div>
  {:else if apps.length > 0}
    {#if filteredApps.length === 0}
      <div class="flex flex-col gap-6 justify-center items-center p-8 card">
        <div class="flex flex-col gap-2 text-center">
          <div class="text-2xl font-semibold">No apps found</div>
          <p>No apps found. Try a different search term</p>
        </div>
      </div>
    {/if}
    <div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8">
      {#each filteredApps as app (app.id)}
        <div class="card p-6 flex-1 flex flex-col gap-4">
          <div class="flex gap-3">
            <div class={cn("flex-1 grid grid-cols-[40px_1fr] items-center gap-y-2 gap-x-3")}>
              <div class="border border-general p-2 rounded-full">
                <AppShapesIcon />
              </div>
              <div class="flex flex-col">
                <h3 class="text-lg font-semibold flex-1 leading-snug">{app.id}</h3>
              </div>
              {#if app.data().description}
                <div class="col-span-2 line-clamp-2">
                  <p title={app.data().description}>{app.data().description}</p>
                </div>
              {/if}
            </div>

            <DropdownMenu.Root>
              <DropdownMenu.Trigger asChild let:builder>
                <Button variant="ghost" builders={[builder]} class="max-h-[32px] px-3">
                  <DotsIcon />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content class="w-52" side="right" align="start">
                <DropdownMenu.Group>
                  <DropdownMenu.Item on:click={() => onEditApp(app.id)} Icon={EditIcon} disabled={!$hasPermission}>
                    Edit
                  </DropdownMenu.Item>
                  <DropdownMenu.Item on:click={() => onShowInstructions(app.id)} Icon={InstructionsIcon}>
                    Integration Instructions
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    class="!text-danger-400"
                    on:click={() => onDeleteConfirm(app.id)}
                    Icon={BinIcon}
                    disabled={!$hasPermission}
                  >
                    Delete
                  </DropdownMenu.Item>
                </DropdownMenu.Group>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </div>

          <div class="border-t border-general pt-3 flex flex-col gap-1">
            <div class="font-semibold">Redirect URLs</div>
            {#if app.data().redirect_uris.length > 0 && app.data().redirect_uris[0] !== ""}
              <p class="" data-testid="app-redirect-url">{app.data().redirect_uris.join(", ")}</p>
            {:else}
              <p class="">-</p>
            {/if}
          </div>
          <AppApiKeys appId={app.id} />
        </div>
      {/each}
    </div>
  {:else}
    <div class="flex flex-col gap-6 justify-center items-center p-8 card">
      <div class="flex flex-col gap-2 text-center">
        <div class="text-2xl font-semibold">You don’t have any apps yet!</div>
        <p>No apps found. Click the "+ Add" button to create a new app.</p>
      </div>
      <Button on:click={onAddApp} disabled={!$hasPermission}>Add First App</Button>
    </div>
  {/if}
</Shell>

<AlertDialog.Root
  open={confirmModalOpen}
  closeOnOutsideClick={true}
  onOpenChange={(isOpen) => {
    if (!isOpen) {
      confirmModalOpen = false;
    }
  }}
>
  <AlertDialog.Content>
    <AlertDialog.Header>
      <AlertDialog.Title>Are You Sure?</AlertDialog.Title>
      <AlertDialog.Description>
        You are about to delete app: <span class="font-medium underline">{selectedApp?.app_id}</span> and all data/keys associated
        with it.
      </AlertDialog.Description>
    </AlertDialog.Header>
    {#if errorMessage}
      <div class="text-danger-400 text-sm">{errorMessage}</div>
    {/if}
    <AlertDialog.Footer>
      <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
      <Button variant="destructive" on:click={onDeleteApp} disabled={isDeleting}
        >{isDeleting ? "Deleting..." : "Delete App"}</Button
      >
    </AlertDialog.Footer>
  </AlertDialog.Content>
</AlertDialog.Root>
