<script lang="ts">
  import { onMount } from "svelte";

  import * as yup from "yup";
  import { Form } from "$ui/form";
  import { Input } from "$ui/input";

  import { Textarea } from "$ui/textarea";
  import { Button } from "$ui/button";

  import BinIcon from "$assets/bin.svelte";
  import { setApp } from "./utils/resources";
  import { cn } from "$utils/ui";

  type AppFormProps = {
    initialValues?: { members: { data?: any; email: any; id?: any; claims?: string[] | undefined }[] } | undefined;
    isEdit: boolean;
    onSuccess: () => void;
    form?: any;
    errors?: any;
    touched?: any;
    isSubmitting?: any;
    handleSubmit?: () => void;
    standaloneForm?: boolean;
    validateField?: any;
    updateValidateField?: () => void;
  };

  let isEdit: boolean = false;
  let appIds: any = [];

  let initialValues = {
    app_id: "",
    description: "",
    redirect_uris: [{ uri: "" }],
  };

  let validationSchema: any;

  const appIdSchema = yup
    .string()
    .trim("App ID cannot include leading and trailing spaces")
    .strict(true)
    .required("App ID is required");

  const uriShape = yup.object().shape({
    uri: yup
      .string()
      .trim("Redirect URI cannot include leading and trailing spaces")
      .strict(true)
      .required("Redirect URI is required")
      .test("is-localhost", "Must be a valid URL", (value) => {
        if (!value) return false;
        const url = new URL(value);
        const isLocalhost = url.hostname == "localhost" && url.protocol.startsWith("http");

        return yup.string().url().isValidSync(value) || isLocalhost;
      }),
  });

  if (isEdit) {
    validationSchema = yup.object().shape({
      app_id: appIdSchema,
      redirect_uris: yup.array().of(uriShape).min(1, "At least one Redirect URI is required"),
    });
  } else {
    validationSchema = yup.object().shape({
      app_id: appIdSchema.test("unique-app-id", "App ID must be unique", function (value) {
        return !appIds.map((id: string) => id.toLowerCase()).includes(value.toLowerCase());
      }),
      redirect_uris: yup.array().of(uriShape).min(1, "At least one Redirect URI is required"),
    });
  }

  const onSubmit = async (values: any) => {
    let submissionValues = {
      ...values,
      redirect_uris: values.redirect_uris.map((uriObj: any) => uriObj.uri),
    };
    await setApp(submissionValues);
  };

  let standaloneForm: AppFormProps["standaloneForm"] = true;
  let onSuccess: AppFormProps["onSuccess"];

  let form: AppFormProps["form"] = null;
  let errors: AppFormProps["errors"] = null;
  let touched: AppFormProps["touched"] = null;
  let isSubmitting: AppFormProps["isSubmitting"] = false;
  let handleSubmit: AppFormProps["handleSubmit"] = () => {};

  let validateField: AppFormProps["validateField"];
  let updateValidateField: AppFormProps["updateValidateField"];

  onMount(() => {
    if (isEdit) {
      validateField("app_id");
    }
  });

  const add = () => {
    $form.redirect_uris = $form.redirect_uris.concat({ uri: "" });
    $errors.redirect_uris = $errors.redirect_uris.concat({ uri: "" });
  };

  const remove = (index: string | number) => () => {
    $form.redirect_uris = $form.redirect_uris.filter((_: any, currentIndex: string | number) => currentIndex !== index);
    $errors.redirect_uris = $errors.redirect_uris.filter(
      (_: any, currentIndex: string | number) => currentIndex !== index,
    );
  };

  export {
    form,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    standaloneForm,
    onSuccess,
    initialValues,
    isEdit,
    appIds,
    // team_id,
  };
</script>

<div class="flex-1 flex flex-col items-stretch">
  <Form
    {initialValues}
    {validationSchema}
    {onSubmit}
    {onSuccess}
    {standaloneForm}
    bind:form
    bind:handleSubmit
    bind:validateField
    bind:updateValidateField
    bind:isSubmitting
    bind:errors
    bind:touched
  >
    {#if $form}
      <Input
        name="app_id"
        label="App ID"
        placeholder="App ID"
        readOnly={isEdit}
        autocomplete="off"
        error={$errors.app_id}
        bind:value={$form.app_id}
        on:input={() => validateField("app_id")}
      />

      <Textarea
        name="description"
        label="Description"
        placeholder="Description"
        type="textarea"
        error={$errors.description}
        bind:value={$form.description}
      />

      <div>
        {#each $form.redirect_uris as { uri }, index}
          <div class="flex flex-col gap-4">
            <div class="flex gap-4 [&>div]:flex-1">
              <Input
                name={`redirect_uris[${index}].uri`}
                label="Redirect URL"
                placeholder="Redirect URL"
                bind:value={uri}
                error={$errors.redirect_uris && $errors?.redirect_uris?.[index]?.uri}
                on:input={() => {
                  validateField(`redirect_uris.${index}.uri` ?? "");
                }}
              />

              <div class="!flex-[0_0_24px] mt-9">
                <button
                  type="button"
                  on:click={remove(index)}
                  disabled={$form.redirect_uris.length === 1}
                  class={cn("[&>svg]:w-3.5", $form.redirect_uris.length === 1 && "opacity-30 cursor-not-allowed")}
                >
                  <BinIcon />
                </button>
              </div>
            </div>
            <div>
              {#if index === $form.redirect_uris.length - 1}
                <Button on:click={add} variant="outline" size="xs">+ Add More</Button>
              {/if}
            </div>
          </div>
        {/each}
      </div>
    {/if}
  </Form>
</div>
