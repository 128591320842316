<svg viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12.0018 7.18875L8.28524 0.62989C8.40864 0.504252 8.55061 0.421844 8.69258 0.366455C7.5966 0.727158 7.09372 1.95787 7.09372 1.95787L0.304212 13.9367C0.208679 14.3123 0.182139 14.6514 0.194081 14.9499H7.60456L12.0018 7.18875Z"
    fill="#34A853"
  />
  <path
    d="M12.002 7.18875L16.3991 14.9499H23.8096C23.8216 14.6514 23.795 14.3123 23.6995 13.9367L16.91 1.95787C16.91 1.95787 16.4058 0.727158 15.3111 0.366455C15.4518 0.421844 15.5951 0.504252 15.7185 0.62989L12.002 7.18875Z"
    fill="#FBBC05"
  />
  <path
    d="M12.0017 7.18909L15.7182 0.630235C15.5948 0.504596 15.4515 0.422188 15.3109 0.366799C15.149 0.314112 14.9752 0.278988 14.7867 0.269531H14.5904H9.41298H9.21661C9.02952 0.277637 8.85438 0.312762 8.6925 0.366799C8.55185 0.422188 8.40855 0.504596 8.28516 0.630235L12.0017 7.18909Z"
    fill="#188038"
  />
  <path
    d="M7.60652 14.95L3.93246 21.4346C3.93246 21.4346 3.81039 21.3751 3.64453 21.2522C4.16598 21.6534 4.67284 21.7385 4.67284 21.7385H19.1024C19.8985 21.7385 20.0644 21.4346 20.0644 21.4346C20.067 21.4332 20.0683 21.4319 20.071 21.4305L16.3996 14.95H7.60652Z"
    fill="#4285F4"
  />
  <path
    d="M7.60677 14.95H0.196289C0.233441 15.8308 0.615576 16.3455 0.615576 16.3455L0.894216 16.8292C0.914119 16.8576 0.926059 16.8738 0.926059 16.8738L1.53243 17.9383L2.89378 20.3079C2.93359 20.4038 2.98003 20.4916 3.02912 20.5754C3.0477 20.6037 3.06495 20.6348 3.08485 20.6618C3.09016 20.6699 3.09546 20.678 3.10077 20.6861C3.26928 20.9239 3.4577 21.1076 3.64611 21.2522C3.81197 21.3765 3.93404 21.4346 3.93404 21.4346L7.60677 14.95Z"
    fill="#1967D2"
  />
  <path
    d="M16.3994 14.95H23.8099C23.7727 15.8308 23.3906 16.3455 23.3906 16.3455L23.112 16.8292C23.0921 16.8576 23.0801 16.8738 23.0801 16.8738L22.4737 17.9383L21.1124 20.3079C21.0726 20.4038 21.0262 20.4916 20.9771 20.5754C20.9585 20.6037 20.9412 20.6348 20.9213 20.6618C20.916 20.6699 20.9107 20.678 20.9054 20.6861C20.7369 20.9239 20.5485 21.1076 20.3601 21.2522C20.1942 21.3765 20.0721 21.4346 20.0721 21.4346L16.3994 14.95Z"
    fill="#EA4335"
  />
</svg>
