<script lang="ts">
  import { cn } from "$lib/utils/ui";
  import CopyIcon from "$assets/copy.svelte";
  import * as Select from "$ui/select";

  import Highlight from "svelte-highlight";
  import { copyToClipboard } from "$lib/utils/helpers";

  import lightTheme from "svelte-highlight/styles/papercolor-light";
  import darkTheme from "svelte-highlight/styles/outrun-dark";
  import Button from "../button/button.svelte";

  let activeTab = 0;

  export let blocks: any[] = [];

  let selectedLanguage = blocks[activeTab] || blocks[0];

  console.log("selectedLanguage", selectedLanguage);
  console.log("selectedLanguage", selectedLanguage.language.name);

  function copyCode() {
    copyToClipboard(blocks[activeTab].code, "Code snippet has been copied to clipboard");
  }

  let darkModeApplied = false;

  function bodyHasDarkClass() {
    return document.body.classList.contains("dark");
  }

  $: darkModeApplied = bodyHasDarkClass();
</script>

<svelte:head>
  {#if darkModeApplied}
    {@html darkTheme}
  {:else}
    {@html lightTheme}
  {/if}
</svelte:head>

<div class="bg-white card overflow-hidden">
  <div class="flex gap-2 justify-between border-b border-general py-2 px-4">
    <div class="hidden md:flex gap-1 items-center">
      {#each blocks as { language, languageLabel }, index}
        <Button
          variant="outline"
          size="sm"
          on:click={() => (activeTab = index)}
          class={cn(
            "px-4",
            index === activeTab
              ? "p bg-white dark:bg-primary/50 border-gray-300"
              : "!bg-transparent !border-transparent",
          )}
        >
          {languageLabel || language.name}
        </Button>
      {/each}
    </div>
    <div class="flex md:hidden -ml-2">
      <Select.Root
        selected={{
          label: blocks[activeTab].languageLabel || blocks[activeTab].language.name,
          value: blocks[activeTab].language.name,
        }}
        onSelectedChange={(selected) =>
          (activeTab = blocks.findIndex((block) => block.language.name === selected.value))}
      >
        <Select.Trigger class="min-w-[180px] pr-3 font-semibold">
          <Select.Value placeholder="Language" />
        </Select.Trigger>
        <Select.Content>
          {#each blocks as { language, languageLabel }, index}
            <Select.Item value={language.name} label={languageLabel || language.name}>
              {languageLabel || language.name}
            </Select.Item>
          {/each}
        </Select.Content>
      </Select.Root>
    </div>
    <Button variant="ghost" on:click={copyCode} class="-mr-2 flex items-center gap-1.5 [&>svg]:w-3.5 ">
      <CopyIcon />
      Copy Code
    </Button>
  </div>

  <div class="text-xs p-2">
    <Highlight language={blocks[activeTab].language} code={blocks[activeTab].code} />
  </div>
</div>
