<script lang="ts">
  import { AlertDialog as AlertDialogPrimitive } from "bits-ui";

  import { cn } from "$utils/ui";
  import { buttonVariants } from "$ui/button";

  type $$Props = AlertDialogPrimitive.CancelProps;
  type $$Events = AlertDialogPrimitive.CancelEvents;

  console.log("AlertDialogPrimitive.CancelEvents", $$restProps);
  let className: $$Props["class"] = undefined;

  export { className as class };
</script>

<AlertDialogPrimitive.Cancel
  class={cn(buttonVariants({ variant: "outline" }), "mt-2 sm:mt-0", className)}
  {...$$restProps}
  on:click
  on:keydown
  let:builder
>
  <slot {builder} />
</AlertDialogPrimitive.Cancel>
