<script lang="ts">
  import { cn } from "$utils/ui";
  import { Input } from "$ui/input";
  import { Button } from "$ui/button";
  import { LoadingSpinner } from "$ui/loading-spinner";
  import { generateAPIKey } from "$lib/utils/resources";
  import { copyToClipboard, formatDate } from "$lib/utils/helpers";
  // @ts-ignore
  import { CalendarDate } from "@internationalized/date";
  import { Calendar } from "$ui/calendar";
  import * as Popover from "$ui/popover";
  import { appKeyStore } from "$lib/utils/stores";

  let expiryDate: any | undefined = undefined;
  let previousExpiryDate: any | undefined = undefined;
  let isOpen = false;

  export let app_id = "";
  export let keyIndex: number = 1;
  export let variant: "default" | "outline" = "outline";

  let errorMessage = "";

  let isGeneratingId = false;

  const today = new Date();
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  const minDate = new CalendarDate(tomorrow.getFullYear(), tomorrow.getMonth() + 1, tomorrow.getDate());

  const onGenerateApiKey = async () => {
    isGeneratingId = true;
    errorMessage = "";

    if (expiryDate === undefined) {
      const expiryDateSuggested = new Date();
      expiryDateSuggested.setDate(expiryDateSuggested.getDate() + 30);
      expiryDate = formatDate(expiryDateSuggested);
    }

    try {
      const apiKey = await generateAPIKey({ app_id, keyIndex, expiryDate });
      copyApiKey(apiKey);
      appKeyStore.set(apiKey);
    } catch (error) {
      errorMessage = "Failed to generate API key";
      console.error("Failed to generate API key:", error);
    } finally {
      isGeneratingId = false;
    }
  };

  const copyApiKey = (codeToCopy: string) => {
    copyToClipboard(codeToCopy, "API Key copied to clipboard!");
  };

  previousExpiryDate = expiryDate;

  $: if (expiryDate !== previousExpiryDate) {
    isOpen = false;
    previousExpiryDate = expiryDate;
  }
</script>

<div class="relative">
  <Input
    data-testid="api-key-input"
    type="text"
    class={cn(
      "w-0 p-0 !border-0 sm:!border sm:px-3 sm:w-full text-current sm:pr-[264px] focus:border-gray-300 font-medium",
      $appKeyStore && !isGeneratingId && "!cursor-copy",
    )}
    placeholder="****-****-****-****"
    bind:value={$appKeyStore}
    readonly
    on:click={() => $appKeyStore && copyApiKey($appKeyStore)}
  />

  <Popover.Root bind:open={isOpen}>
    <Popover.Trigger asChild let:builder class="absolute top-0">
      <Button
        variant="outline"
        class={cn(
          "!text-[13px] !justify-start !font-normal border-l border-r",
          "absolute top-0 sm:right-[100px] rounded-r-none sm:rounded-none w-1/2 sm:w-[160px] px-3 sm:px-4",
          !expiryDate && "text-gray dark:text-gray-400",
        )}
        builders={[builder]}
      >
        {expiryDate ? expiryDate : "Set Expiry Date"}
      </Button>
    </Popover.Trigger>
    <Popover.Content class="w-auto p-0">
      <Calendar bind:value={expiryDate} minValue={minDate} />
    </Popover.Content>
  </Popover.Root>

  <Button
    data-testid="generate-button"
    class={cn(
      "absolute right-0 top-0",
      "rounded-l-none w-1/2 sm:w-auto min-w-[100px] rounded-r-md",
      "border border-l-0",
      variant === "outline" ? "bg-gray-100 dark:!border-primary-700 dark:bg-white/10" : "border-primary-700/70",
      "disabled:!cursor-not-allowed",
    )}
    on:click={onGenerateApiKey}
    {variant}
    disabled={isGeneratingId || keyIndex > 2}
  >
    {#if isGeneratingId}
      <div
        class={cn(
          "[&>svg]:w-4",
          "[&>svg]:dark:!fill-white [&>svg>circle:nth-child(even)]:dark:!stroke-white",
          variant !== "outline" && "[&>svg]:!fill-white [&>svg>circle:nth-child(even)]:!stroke-white",
        )}
      >
        <LoadingSpinner />
      </div>
    {:else}
      Generate
    {/if}
  </Button>
</div>

{#if errorMessage}
  <div class="text-danger-400 text-sm">{errorMessage}</div>
{/if}
