<script lang="ts">
  import { cn } from "$utils/ui";
  import type { HTMLAttributes } from "svelte/elements";

  type $$Props = HTMLAttributes<HTMLDivElement>;

  let className: $$Props["class"] = undefined;
  export { className as class };
</script>

<div class={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-3 mt-4", className)} {...$$restProps}>
  <slot />
</div>
