<script lang="ts">
  import { Popover as PopoverPrimitive } from "bits-ui";
  import { cn, flyAndScale } from "$utils/ui";

  type $$Props = PopoverPrimitive.ContentProps;
  let className: $$Props["class"] = undefined;
  export let transition: $$Props["transition"] = flyAndScale;
  export let transitionConfig: $$Props["transitionConfig"] = undefined;
  export { className as class };
</script>

<PopoverPrimitive.Content
  {transition}
  {transitionConfig}
  class={cn("popover z-50 w-72 rounded-md border p-4 shadow-md outline-none", className)}
  {...$$restProps}
>
  <slot />
</PopoverPrimitive.Content>
