<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M11.6989 1.38894C10.881 0.780208 9.85272 0.599613 8.8762 0.892508L2.27073 2.8741C0.909936 3.2826 -0.00488281 4.51115 -0.00488281 5.93245V20.0506C-0.00488281 21.07 0.464109 22.0036 1.282 22.6113C2.10003 23.2201 3.1294 23.4007 4.10479 23.1078L9.0358 21.6282C8.8816 21.1196 8.80348 20.5889 8.80348 20.0506V13.6389C8.80348 12.2925 9.29451 11.0066 10.1884 10.0202C10.8445 9.2935 11.6956 8.75742 12.6468 8.47119L12.9859 8.37434V3.95078C12.9859 2.9313 12.5169 1.99774 11.6988 1.38894H11.6989Z"
    fill="#6E55FF"
  />
  <path
    d="M23.9957 11.6573V18.0689C23.9957 19.4892 23.082 20.7189 21.7201 21.1262L15.1146 23.1079C14.8086 23.2003 14.4971 23.2456 14.1888 23.2456C13.5139 23.2456 12.8533 23.0298 12.2918 22.6114C12.0353 22.4199 11.8129 22.1986 11.6302 21.9509C11.4078 21.6536 11.2416 21.3223 11.137 20.9666C11.1359 20.9666 11.1348 20.9666 11.1337 20.9677L11.1359 20.9655C11.0511 20.6737 11.0049 20.3666 11.0049 20.0506V13.6389C11.0049 12.9806 11.2008 12.363 11.5466 11.8499C11.8923 11.3369 12.3876 10.9274 12.9866 10.6841C13.0824 10.6455 13.1803 10.6102 13.2806 10.5806L14.0886 10.3384L19.8861 8.59893C20.8626 8.30603 21.8909 8.48663 22.7088 9.09543C23.5269 9.70424 23.9958 10.6378 23.9958 11.6573H23.9957Z"
    fill="#6E55FF"
  />
</svg>
