<script lang="ts">
  import { deleteMember, getMembers, getPermission } from "$lib/utils/resources";

  import * as DropdownMenu from "$ui/dropdown-menu";

  import Shell from "$lib/Shell.svelte";
  import MembersForm from "$lib/MembersForm.svelte";

  import * as AlertDialog from "$ui/alert-dialog";
  import * as Drawer from "$ui/drawer";
  import * as Table from "$ui/table";
  import { Button } from "$ui/button";
  import { LoadingSpinner } from "$ui/loading-spinner";
  import { SearchBar } from "$ui/search-bar";

  import { formatDateTime, fuzzyMatch, isArrayEqual } from "$utils/helpers";
  import { ROLES } from "$utils/constants";

  import DotsIcon from "$assets/dots.svelte";
  import EditIcon from "$assets/edit.svelte";
  import BinIcon from "$assets/bin.svelte";

  let isLoading: boolean = true;
  let members: any[] = [];
  let filteredMembers: any[] = [];

  let drawerOpen = false;
  let confirmModalOpen = false;
  let isDeleting: boolean = false;
  let errorMessage: string | null = "";
  let selectedMember: { data?: any; email: any; id?: any; claims?: string[] } | null = null;

  $: {
    getMembers((newMembers) => {
      members = newMembers;
      filteredMembers = newMembers.sort((a, b) => a.data().modified - b.data().modified);
      isLoading = false;
    });
  }

  const onAddMember = () => {
    selectedMember = null;
    openDrawer();
  };

  const onEditMember = (memberId: string) => {
    const member = members.find((member) => member.id === memberId);
    selectedMember = {
      ...member.data(),
      email: memberId,
    };
    openDrawer();
  };

  const onDeleteConfirm = (memberId: string) => {
    const member = members.find((member) => member.id === memberId);
    selectedMember = {
      ...member.data(),
      email: memberId,
    };

    confirmModalOpen = true;
  };

  const onDeleteMember = async () => {
    try {
      isDeleting = true;
      await deleteMember(selectedMember?.email);
      confirmModalOpen = false;
    } catch (error: any) {
      errorMessage = "Error deleting Member. " + error?.message;
      console.error("Error deleting Member:", error);
    } finally {
      isDeleting = false;
    }
  };

  const openDrawer = () => {
    drawerOpen = true;
  };

  const closeDrawer = () => {
    drawerOpen = false;
  };

  const onSearch = (newTerm: string) => {
    filteredMembers =
      newTerm !== ""
        ? members.filter((member) => {
            const memberData = member.data();
            const roleLabel = ROLES.find((role) => isArrayEqual(role.value, memberData.claims))?.label ?? "";
            console.log("member.id", member.id, "roleLabel", roleLabel);
            return fuzzyMatch(member.id, newTerm) || fuzzyMatch(roleLabel, newTerm);
          })
        : members;
  };

  const hasPermission = getPermission("users:write");
</script>

<Shell title="Members" class="flex flex-col gap-8">
  <div class="flex flex-col-reverse xs:flex-row justify-between xs:items-center gap-4">
    <div class="w-full md:max-w-[300px]">
      <SearchBar onChange={onSearch} />
    </div>
    <Drawer.Root bind:open={drawerOpen}>
      <Drawer.Trigger disabled={!$hasPermission}>
        <Button on:click={onAddMember} class="w-full min-h-[46px]" disabled={!$hasPermission}>+ Add Members</Button>
      </Drawer.Trigger>
      <Drawer.Content>
        <Drawer.Header class="pt-6 px-6 pb-0 relative">
          <Drawer.Title class="text-xl">
            {selectedMember ? "Edit Member" : "Add New Members"}
          </Drawer.Title>
          <Drawer.Description class="p text-base">
            {selectedMember ? "Make changes to an Existing Member" : "Invite new members to join your team"}
          </Drawer.Description>
        </Drawer.Header>
        <div class="p-6 flex-1 flex flex-col">
          <MembersForm
            initialValues={selectedMember ? { members: [selectedMember] } : undefined}
            isEdit={selectedMember ? true : false}
            onSuccess={() => {
              closeDrawer();
            }}
          />
        </div>
      </Drawer.Content>
    </Drawer.Root>
  </div>
  {#if isLoading}
    <div class="flex justify-center items-center">
      <LoadingSpinner />
    </div>
  {:else}
    <div class="card">
      <Table.Root>
        <Table.Header>
          <Table.Row class="hidden sm:table-row">
            <Table.Head class="font-semibold">User</Table.Head>
            <Table.Head class="font-semibold hidden xs:table-cell">Role</Table.Head>
            <Table.Head class="font-semibold hidden xs:table-cell">Last Modified</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {#if filteredMembers.length === 0}
            <Table.Row>
              <Table.Cell class="text-center py-8" colspan={4}>No members found</Table.Cell>
            </Table.Row>
          {/if}
          {#each filteredMembers as member (member.id)}
            <Table.Row class="py-2 sm:py-0">
              <Table.Cell data-label="User" class="font-medium truncate">
                {member.id}
              </Table.Cell>
              <Table.Cell data-label="Role">
                {ROLES.find((role) => isArrayEqual(role.value, member.data().claims))?.label ?? "-"}
              </Table.Cell>
              <Table.Cell data-label="Modified">{formatDateTime(member.data().modified)}</Table.Cell>
              <Table.Cell
                class="flex sm:justify-end !absolute sm:!relative top-1 -right-7 sm:top-auto sm:right-auto before:!hidden !w-auto"
              >
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild let:builder>
                    <Button variant="ghost" builders={[builder]} class="[&>svg]:rotate-90 max-h-[32px] px-3">
                      <DotsIcon />
                    </Button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content class="w-40" side="right" align="start" sideOffset={10}>
                    <DropdownMenu.Group>
                      <DropdownMenu.Item
                        on:click={() => onEditMember(member.id)}
                        Icon={EditIcon}
                        disabled={!$hasPermission}
                      >
                        Edit
                      </DropdownMenu.Item>
                      <DropdownMenu.Item
                        class="!text-danger-400"
                        on:click={() => onDeleteConfirm(member.id)}
                        Icon={BinIcon}
                        disabled={!$hasPermission}
                      >
                        Delete
                      </DropdownMenu.Item>
                    </DropdownMenu.Group>
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              </Table.Cell>
            </Table.Row>
          {/each}
        </Table.Body>
      </Table.Root>
    </div>
  {/if}
</Shell>

<AlertDialog.Root
  open={confirmModalOpen}
  closeOnOutsideClick={true}
  onOpenChange={(isOpen) => {
    if (!isOpen) {
      confirmModalOpen = false;
      selectedMember = null;
    }
  }}
>
  <AlertDialog.Content>
    <AlertDialog.Header>
      <AlertDialog.Title>Are You Sure?</AlertDialog.Title>
      <AlertDialog.Description>
        You are about to delete member:
        <span class="font-medium">
          {selectedMember?.email}
        </span>
      </AlertDialog.Description>
    </AlertDialog.Header>
    {#if errorMessage}
      <div class="text-danger-400 text-sm">{errorMessage}</div>
    {/if}
    <AlertDialog.Footer>
      <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
      <Button variant="destructive" on:click={onDeleteMember} disabled={isDeleting}>
        {isDeleting ? "Deleting..." : "Delete Member"}
      </Button>
    </AlertDialog.Footer>
  </AlertDialog.Content>
</AlertDialog.Root>
