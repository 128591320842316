import { Query, QueryFieldFilterConstraint, collection, doc, getDoc, onSnapshot, query } from "firebase/firestore";
import { derived, get } from "svelte/store";
import { dbStore, envStore, selectedTeamIdStore, userStore } from "$utils/stores";

// Reusable functions
export const request = async (path: string, body: object, method = "PUT") => {
  const env = get(envStore);
  const ENDPOINT = (env as { apiRoots?: { management?: string } })?.apiRoots?.management;

  // @ts-ignore
  const userToken = await get(userStore).getIdToken();

  const response = await fetch(`${ENDPOINT}/${path}`, {
    method: method,
    body: JSON.stringify(body),
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }

  if (response.status === 204) return;

  return response?.json();
};

const getCollection = async (path: string) => {
  const db = await get(dbStore);
  if (!db) {
    throw new Error("Firestore instance not found");
  }
  return collection(db, path);
};

const getDocument = async (path: string) => {
  const db = await get(dbStore);
  if (!db) {
    throw new Error("Firestore instance not found");
  }
  const docRef = await doc(db, path);
  return (await getDoc(docRef)).data();
};

function subscribeToCollection(pathTemplate: (teamId: string) => string, callback: any, filter?: QueryFieldFilterConstraint) {
  let unsubscribe = () => {};

  const stopTeamIdSubscription = selectedTeamIdStore.subscribe(async (teamId) => {
    const path = pathTemplate(teamId);
    let q: Query;

    if (filter) {
      q = query(await getCollection(path), filter);
    } else {
      q = query(await getCollection(path));
    }

    unsubscribe = onSnapshot(q, (snapshot) => {
      const items = snapshot.docs;
      callback(items);
    });
  });

  return () => {
    unsubscribe();
    stopTeamIdSubscription();
  };
}

// USER
export async function getUser(uid?: string) {
  if (uid) {
    const userDocument = await getDocument(`users/${uid}`);
    return userDocument;
  } else {
    const user = await get(userStore);
    const userDocument = await getDocument(`users/${user?.uid}`);

    return userDocument;
  }
}

export async function setUser(values: any) {
  userStore.update((user) => {
    if (user) {
      user.preferences = values;
    }
    return user;
  });

  return await request(`me/`, {
    ...values,
  });
}

// TEAMS
export async function getTeams(callback: (teams: any[]) => void) {
  const user = await getUser();

  const db = get(dbStore);

  const teamIds = Object.keys(user?.team_claims ?? {});
  const teamsLoadingState = new Set<string>();
  teamIds.forEach((teamId) => {
    teamsLoadingState.add(teamId);
  });
  const teams: Record<string, any> = {};

  if (teamIds.length === 0) {
    callback([]);
    return () => {};
  }

  const snapshotCancelFns = teamIds.map((teamId) => {
    return onSnapshot(
      // @ts-ignore
      doc(db, `teams/${teamId}`),
      (doc) => {
        teams[doc.id] = {
          id: doc.id,
          ...doc.data(),
        };
        teamsLoadingState.delete(teamId);
        if (teamsLoadingState.size === 0) {
          // All teams have loaded (successfully or not)
          const filteredTeams = Object.values(teams)
            .filter((team) => team.alias)
            .sort((a, b) => a.alias.localeCompare(b.alias));
          callback(filteredTeams);
        }
      },
      (...args) => {
        console.error(`Error getting teamId=${teamId}`, args);
        teamsLoadingState.delete(teamId);
      },
    );
  });

  return () => {
    snapshotCancelFns.forEach((fn) => fn());
  };
}

// APPS
export function getApps(callback: (apps: any[]) => void) {
  return subscribeToCollection((teamId: string) => `/teams/${teamId}/apps`, callback);
}

export async function setApp({
  app_id,
  description,
  redirect_uris,
}: {
  app_id: string;
  description: string;
  redirect_uris: string[];
}) {
  const teamId = await get(selectedTeamIdStore);
  return await request(`teams/${teamId}/apps/${app_id}/`, {
    description,
    redirect_uris,
  });
}

export async function deleteApp(app_id: string) {
  const teamId = await get(selectedTeamIdStore);
  return await request(`teams/${teamId}/apps/${app_id}/`, {}, "DELETE");
}

// API Keys
export async function getAPIKeys(app_id: string, callback: { (newKeys: any): void; (newKeys: string | any[]): void }) {
  return subscribeToCollection((teamId: string) => `/teams/${teamId}/apps/${app_id}/keys`, callback);
}

export async function generateAPIKey({
  app_id,
  keyIndex,
  expiryDate,
}: {
  app_id: string;
  keyIndex: string | number;
  expiryDate: string;
}) {
  const teamId = get(selectedTeamIdStore);
  const response = await request(`teams/${teamId}/apps/${app_id}/keys/${keyIndex}?expiry=${expiryDate}`, {}, "POST");

  return response.appKey
}

export async function deleteAPIKey({ app_id, keyIndex }: { app_id: string; keyIndex: string }) {
  const teamId = get(selectedTeamIdStore);
  return request(`teams/${teamId}/apps/${app_id}/keys/${keyIndex}`, {}, "DELETE");
}

// MEMBERS
export async function getMembers(callback: (members: any[]) => void) {
  return subscribeToCollection((teamId: string) => `/teams/${teamId}/members`, callback);
}

export async function setMember({ member_id, claims }: { member_id: string; claims: any }) {
  const teamId = get(selectedTeamIdStore);
  return await request(`teams/${teamId}/members/${member_id}/`, {
    claims,
  });
}

export async function deleteMember(member_id: string) {
  const teamId = get(selectedTeamIdStore);
  return await request(`teams/${teamId}/members/${member_id}/`, {}, "DELETE");
}

// PERMISSIONS
export function getPermission(permission: string) {
  return derived(
    selectedTeamIdStore,
    ($selectedTeamIdStore, set) => {
      (async () => {
        if ($selectedTeamIdStore) {
          try {
            const user = await getUser();
            const selectedTeamId = $selectedTeamIdStore;
            const teamClaims = user?.team_claims[selectedTeamId];
            const hasPermission = teamClaims ? teamClaims.includes(permission) : false;
            set(hasPermission); // Update the store with the new value
          } catch (error) {
            console.error("Failed to get permission:", error);
            set(false);
          }
        } else {
          set(false);
        }
      })();
    },
    false,
  );
}
