<script lang="ts">
  import { request, setUser } from "$utils/resources";
  import { envStore, selectedTeamIdStore, teamsStore } from "$utils/stores";

  import * as yup from "yup";
  import { Form } from "$ui/form";
  import { Input } from "$ui/input";
  import { LoadingSpinner } from "$ui/loading-spinner";

  import { cn } from "$utils/ui";
  import CheckCircleIcon from "$assets/check-circle.svelte";
  import Field from "./ui/field/field.svelte";

  const debounce = (func: { (teamName: any): Promise<void>; (arg0: any): void }, delay: number | undefined) => {
    let timeout: string | number | NodeJS.Timeout | undefined;
    return (...args: any) => {
      clearTimeout(timeout);
      // @ts-ignore
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const initialValues = {
    alias: "",
    team_id: "",
    agreed: false,
  };

  const validationSchema = yup.object().shape({
    alias: yup.string().required("Alias is required"),
    team_id: yup
      .string()
      .required("Team ID is required")
      .matches(/^[a-z0-9-]+$/, "Team ID must be lowercase and contain only letters, numbers, and hyphens"),
    agreed: yup.boolean().oneOf([true], "You must agree to the terms and conditions"),
  });

  const onSubmit = async (values: any) => {
    await request(`teams/${values.team_id}/`, { alias: values.alias });
    selectedTeamIdStore.set(values.team_id);
    const teams = $teamsStore;

    if (teams.length === 0) {
      await setUser({ preferences: { default_team: values.team_id } });
    }
  };

  let standaloneForm: boolean | undefined;
  let onSuccess: () => void;

  let form: any;
  let errors: any;
  let touched: any;
  let isSubmitting: any;
  let handleSubmit: () => void;

  let validateField: (field?: any) => any;
  let updateValidateField: (field?: any, value?: any) => void;

  let isGeneratingId = false;

  const debounceValidation = debounce(async (teamName) => {
    if (teamName) {
      isGeneratingId = true;

      errors.set({ ...$errors, tempFormError: "generating" });

      const response = await request(`generate-team-id?alias=${teamName}`, {}, "POST");
      const generatedTeamId = response.id

      updateValidateField("team_id", generatedTeamId);
      isGeneratingId = false;

      const resetErrors = { ...$errors };
      delete resetErrors.tempFormError;
      errors.set(resetErrors);
    } else {
      updateValidateField && updateValidateField("team_id", "");
    }
  }, 500);

  let tosLink = $envStore?.legals?.termsOfService || "https://www.redactive.ai";
  let privacyLink = $envStore?.legals?.privacyPolicy || "https://www.redactive.ai/privacy";

  export { form, errors, touched, isSubmitting, handleSubmit, standaloneForm, onSuccess };
</script>

<div class="flex-1 flex flex-col items-stretch">
  <Form
    {initialValues}
    {validationSchema}
    {onSubmit}
    {onSuccess}
    {standaloneForm}
    bind:form
    bind:handleSubmit
    bind:validateField
    bind:updateValidateField
    bind:isSubmitting
    bind:errors
    bind:touched
  >
    {#if $form}
      <Input
        name="alias"
        label="Team Name"
        placeholder="Team Name"
        autocomplete="off"
        error={$errors?.alias}
        bind:value={$form.alias}
        on:input={() => {
          validateField("alias");
          debounceValidation($form.alias);
        }}
      />

      <div class="relative">
        <Input
          name="team_id"
          label="Team ID"
          placeholder="Team ID"
          readonly
          error={$errors?.team_id}
          bind:value={$form.team_id}
          on:input={() => {
            validateField("team_id");
          }}
        />
        <div class="absolute top-7 right-0 h-10 px-3 flex items-center justify-center">
          {#if isGeneratingId}
            <div class="[&>svg]:w-5">
              <LoadingSpinner />
            </div>
          {:else}
            <div
              class={cn("[&>svg]:w-5", {
                "text-success-400": $form.team_id,
                "text-gray-300": !$form.team_id,
              })}
            >
              <CheckCircleIcon />
            </div>
          {/if}
        </div>
      </div>

      <Field label="" error={$errors.agreed}>
        <label class="mt-4 flex items-center gap-2 cursor-pointer" for="agreed">
          <input
            type="checkbox"
            id="agreed"
            name="agreed"
            bind:checked={$form.agreed}
            on:change={() => validateField("agreed")}
          />
          <p class="-mt-0.5">
            I agree to
            <a
              href={tosLink}
              target="_blank"
              rel="noreferrer"
              class="text-primary dark:text-primary-700 underline text-sm">Terms of Service</a
            >
            and
            <a
              href={privacyLink}
              target="_blank"
              rel="noreferrer"
              class="text-primary dark:text-primary-700 underline text-sm">Privacy Policy</a
            >
          </p>
        </label>
      </Field>
    {/if}
  </Form>
</div>
