import firebase from "firebase/compat/app";
import { AuthState, authState, envStore, userStore, dbStore, type Environment } from "./stores";
import { location, replace } from "svelte-spa-router";
import { get } from "svelte/store";
import { getFirestore } from "firebase/firestore";
import { getUser } from "./resources";

interface Preferences {
  name?: string;
  default_team?: string;
  primary_email?: string;
}

interface ExtendedUser extends firebase.User {
  preferences: Preferences;
}

export const initApp = async () => {
  if (import.meta.env.MODE === "development") {
    console.info("Development mode, using environment variables");
    const res = await fetch("/env.local.json");
    const data = await res.json();
    envStore.set(data);
  } else {
    const res = await fetch("/env.json");
    const data = await res.json();
    envStore.set(data);
  }

  const data = get(envStore);
  if (!data) {
    throw new Error("Environment data not found");
  }

  const app = firebase.initializeApp(data.firebaseConfig);

  const db = getFirestore(app);
  // @ts-ignore
  dbStore.set(db);

  console.info("Initialized App", data.firebaseConfig);

  // wait for the first auth state come
  await new Promise((resolve, reject) => {
    const unsubscribe = app.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        userStore.set(user as ExtendedUser);
        console.debug(user);
        getUser(user.uid).then((userPreferences) => {
          userStore.update((user) => {
            if (user) {
              user.preferences = userPreferences?.preferences;
            }
            return user;
          });
        });
        authState.set(AuthState.Authenticated);
      }
      unsubscribe();
      resolve(user);
    }, reject);
  });

  // monitor if user log out
  app.auth().onAuthStateChanged((user: firebase.UserInfo | null) => {
    if (user) {
      userStore.set(user as ExtendedUser);
      authState.set(AuthState.Authenticated);
    } else {
      userStore.set(null);
      authState.set(AuthState.Unauthenticated);
      if (!get(location).startsWith("/login")) {
        replace("/login");
      }
    }
  });
};
