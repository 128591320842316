<script lang="ts">
  import Sidebar from "./Sidebar.svelte";
  import { cn } from "$utils/ui";

  let className = "";
  let title = "";
  let hasSidebar = true;
  let hasBackground = false;
  let wrapperClass = "";
  let mainClass = "";

  export { className as class, title, hasSidebar, wrapperClass, mainClass, hasBackground };
</script>

<div
  class={cn(
    "flex-1 flex flex-col lg:flex-row relative",
    wrapperClass,
    hasBackground ? "bg-background-gray dark:bg-background-dark" : "bg-background-light dark:bg-background-dark",
  )}
>
  {#if hasSidebar}
    <Sidebar />
  {/if}

  <main class={cn("flex-1 flex flex-col py-5 sm:py-8 lg:py-10", mainClass, hasSidebar ? "max-w-[1640px]" : "")}>
    {#if title}
      <div class="px-6 lg:px-10 mb-5 pb-5 sm:mb-7 sm:pb-7 border-b border-general text-3xl sm:text-4xl font-semibold">
        {title}
      </div>
    {/if}
    <div class={cn("px-6 lg:px-10 flex-1", className)}>
      <slot />
    </div>
  </main>
</div>
