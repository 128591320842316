<script lang="ts">
  import { cn } from "$utils/ui";
  import { Select as SelectPrimitive } from "bits-ui";
  import { Check } from "lucide-svelte";

  type $$Props = SelectPrimitive.ItemProps & { isSelected?: boolean };
  type $$Events = SelectPrimitive.ItemEvents;

  let className: $$Props["class"] = undefined;
  export let value: $$Props["value"];
  export let label: $$Props["label"] = undefined;
  export let disabled: $$Props["disabled"] = undefined;
  export let isSelected: $$Props["isSelected"];
  export { className as class };
</script>

<SelectPrimitive.Item
  {value}
  {disabled}
  {label}
  class={cn(
    "popover-item relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none",
    "cursor-pointer",
    className,
  )}
  {...$$restProps}
  on:click
  on:keydown
  on:focusin
  on:focusout
  on:pointerleave
  on:pointermove
>
  {#if isSelected}
    <span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <Check class="h-4 w-4" />
    </span>
  {/if}

  <span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
    <SelectPrimitive.ItemIndicator>
      <Check class="h-4 w-4" />
    </SelectPrimitive.ItemIndicator>
  </span>

  <slot />
</SelectPrimitive.Item>
