<script lang="ts">
  import { CodeBlock } from "$ui/code-block";
  import ApiKeyHelper from "./ApiKeyHelper.svelte";
  import { CODE_BLOCKS_QUERY, CODE_BLOCKS_OPENAI } from "./utils/constants";
  import { appKeyStore } from "./utils/stores";

  import "highlight.js/styles/github.css";

  let data_sources: string[] = [];
  let hideGenerateKey = false;

  const exampleKey = "test_1234567";

  $: queryCodeBlocks = CODE_BLOCKS_QUERY(data_sources, $appKeyStore || exampleKey);
  $: apiPipelineBlocks = CODE_BLOCKS_OPENAI(data_sources, $appKeyStore || exampleKey);

  let onSuccess: () => void;

  const handleSubmit = async () => {
    onSuccess && onSuccess();
    return;
  };

  export { data_sources, handleSubmit, onSuccess, hideGenerateKey };
</script>

<div class="flex flex-col gap-4">
  <div class="flex flex-col gap-6">
    <div class="flex flex-col gap-4">
      <ApiKeyHelper {hideGenerateKey}/>
      <div>
        <h3 class="font-semibold">Query Chunks</h3>
        <p>Your backend can query the index and find relevant chunks.</p>
      </div>
      <CodeBlock blocks={queryCodeBlocks} />
      <div>
        <h3 class="font-semibold">OpenAI RAG Integration</h3>
        <p>You can use Redactive to fetch context chunks for your AI pipeline.</p>
      </div>
      <CodeBlock blocks={apiPipelineBlocks} />
    </div>
  </div>
</div>
