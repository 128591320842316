// @ts-nocheck
import ConfluenceIcon from "$assets/confluence.svelte";
import SlackIcom from "$assets/slack.svelte";
import GoogleDriveIcon from "$assets/g-drive.svelte";
import SalesforceIcon from "$assets/salesforce.svelte";
import TeamsIcon from "$assets/teams.svelte";
import NotionIcon from "$assets/notion.svelte";
import SharepointIcon from "$assets/sharepoint.svelte";
import JiraIcon from "$assets/jira.svelte";
import GCalIcon from "$assets/g-cal.svelte";
import GDocsIcon from "$assets/g-docs.svelte";
import GSheetsIcon from "$assets/g-sheets.svelte";
import ZendeskIcon from "$assets/zendesk.svelte";

import javascript from "svelte-highlight/languages/javascript";
import typescript from "svelte-highlight/languages/typescript";
import python from "svelte-highlight/languages/python";

import TS_CODE_BLOCKS_CAPTURE_CALLBACK from "$utils/code_blocks/capture_callback.ts?raw"

import TS_CONNECT_DS from "$utils/code_blocks/connect_ds.ts?raw"

import TS_INIT from "$utils/code_blocks/sdk_init.ts?raw"
import PY_INIT from "$utils/code_blocks/sdk_init.py?raw"

import TS_QUERY from "$utils/code_blocks/query.ts?raw"
import JS_QUERY from "$utils/code_blocks/query.js?raw"
import PY_QUERY from "$utils/code_blocks/query.py?raw"

import TS_OPENAI from "$utils/code_blocks/openai.ts?raw"
import JS_OPENAI from "$utils/code_blocks/openai.js?raw"
import PY_OPENAI from "$utils/code_blocks/openai.py?raw"

import TS_CODE_BLOCK_REFRESH_TOKEN from "$utils/code_blocks/refresh_token.ts?raw"

export const ROLES = [
  { value: ["team:read", "team:write", "users:write", "apps:write", "app_keys:write"], label: "Admin" },
  { value: ["team:read", "users:read", "apps:write", "app_keys:write"], label: "Developer" },
  { value: ["team:read", "users:write", "apps:read"], label: "Billing" },
];

const AVAILABLE_DATA_SOURCES = [
  {
    value: "confluence",
    title: "Confluence",
    subtitle: "atlassian.com",
    icon: ConfluenceIcon,
    enabled: true,
    preview: false,
  },
  {
    value: "slack",
    title: "Slack",
    subtitle: "slack.com",
    icon: SlackIcom,
    enabled: true,
    preview: false,
  },
  {
    value: "teams",
    title: "Microsoft Teams",
    subtitle: "ms-teams.com",
    icon: TeamsIcon,
    enabled: false,
    preview: false,
  },
  {
    value: "notion",
    title: "Notion",
    subtitle: "notion.com",
    icon: NotionIcon,
    enabled: false,
    preview: false,
  },
  {
    value: "sharepoint",
    title: "Sharepoint",
    subtitle: "sharepoint.com",
    icon: SharepointIcon,
    enabled: true,
    preview: false,
  },
  {
    value: "jira",
    title: "Jira",
    subtitle: "jira.com",
    icon: JiraIcon,
    enabled: true,
    preview: false,
  },
  {
    value: "google-drive",
    title: "Google Drive",
    subtitle: "drive.google.com",
    icon: GoogleDriveIcon,
    enabled: true,
    preview: false,
  },
  {
    value: "gcal",
    title: "Google Calendar",
    subtitle: "calendar.google.com",
    icon: GCalIcon,
    enabled: false,
    preview: false,
  },
  {
    value: "gdocs",
    title: "Google Sheets",
    subtitle: "docs.google.com",
    icon: GDocsIcon,
    enabled: false,
    preview: false,
  },
  {
    value: "gsheets",
    title: "Google Docs",
    subtitle: "sheets.google.com",
    icon: GSheetsIcon,
    enabled: false,
    preview: false,
  },
  {
    value: "salesforce",
    title: "Salesforce",
    subtitle: "salesforce.com",
    icon: SalesforceIcon,
    enabled: false,
    preview: false,
  },
  {
    value: "zendesk",
    title: "Zendesk",
    subtitle: "zendesk.com",
    icon: ZendeskIcon,
    enabled: true,
    preview: true,
  },
];

export const DATA_SOURCES = AVAILABLE_DATA_SOURCES.filter((x) => x.enabled);

// BACKEND CODE BLOCKS
export const CODE_BLOCKS_INSTALL = [
  {
    language: javascript,
    languageLabel: "npm",
    code: `$ npm install @redactive/redactive`,
  },
  {
    language: python,
    languageLabel: "pip",
    code: `$ pip install redactive`,
  },
];

export const CODE_BLOCKS_SDK_INIT = (data_sources, apiKey) => [
{
  language: typescript,
  code: TS_INIT.replace("{{apiKey}}", apiKey).replace("{{data_sources}}", data_sources.map(ds => `"${ds}"`).join(" | "))
},
{
  language: python,
  code: PY_INIT.replace("{{apiKey}}", apiKey).replace("{{data_sources}}", data_sources.map(ds => `"${ds}"`).join(" | "))
}
]

export const CODE_BLOCKS_CAPTURE_CALLBACK = (data_sources, apiKey) => [
  {
    language: typescript,
    code: TS_CODE_BLOCKS_CAPTURE_CALLBACK.replace("{{apiKey}}", apiKey)
  }
]

export const CODE_BLOCKS_REFRESH_TOKEN = (data_sources, apiKey) => [
  {
    language: typescript,
    code: TS_CODE_BLOCK_REFRESH_TOKEN.replace("{{apiKey}}", apiKey)
  }
]

export const CODE_BLOCKS_CONNECT_DS = (data_sources, apiKey, redirect_uri, endpoint) => [{
  language: typescript,
  code: TS_CONNECT_DS
    .replace("{{apiKey}}", apiKey)
    .replace("{{data_sources}}", data_sources.map(ds => `"${ds}"`).join(" | "))
    .replace("{{redirect_uri}}", redirect_uri ?? "")
    .replace("{{endpoint}}", endpoint ?? "")
}]

export const CODE_BLOCKS_QUERY = (data_sources, apiKey) => [
  {
    language: javascript,
    // languageLabel: "node",
    code: JS_QUERY.replace("{{apiKey}}", apiKey),
  },
  {
    language: typescript,
    code: TS_QUERY.replace("{{apiKey}}", apiKey)
  },
  {
    language: python,
    code: PY_QUERY.replace("{{apiKey}}", apiKey)
  },
];

export const CODE_BLOCKS_OPENAI = (data_sources, apiKey) => [
  {
    language: javascript,
    // languageLabel: "node",
    code: JS_OPENAI.replace("{{apiKey}}", apiKey),
  },
  {
    language: typescript,
    code: TS_OPENAI.replace("{{apiKey}}", apiKey)
  },
  {
    language: python,
    code: PY_OPENAI.replace("{{apiKey}}", apiKey)
  },
];
