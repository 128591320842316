<script lang="ts">
  // @ts-ignore
  import firebase from "firebase/compat/app";
  import { selectedTeamIdStore, userStore } from "$utils/stores";

  import { link } from "svelte-spa-router";
  import active from "svelte-spa-router/active";

  import TeamSwitcher from "./TeamSwitcher.svelte";
  import { Button } from "$ui/button";

  import HomeIcon from "$assets/home.svelte";
  import UserIcon from "$assets/user.svelte";
  import AppsIcon from "$assets/apps.svelte";
  import XIcon from "$assets/x.svelte";

  import SupportIcon from "$assets/life-buoy.svelte";
  import SettingsIcon from "$assets/settings.svelte";
  import LogoutIcon from "$assets/logout.svelte";

  import Logo from "../assets/logo.svelte";
  import Hamburger from "$assets/hamburger.svelte";
  import { cn } from "./utils/ui";

  interface NavLink {
    label: string;
    path: string;
    icon: any;
  }

  let navLinks: NavLink[] = [];

  function updateNavLinks() {
    navLinks = [
      { label: "Overview", path: `/${$selectedTeamIdStore}`, icon: HomeIcon },
      { label: "Apps", path: `/${$selectedTeamIdStore}/apps`, icon: AppsIcon },
      { label: "Members", path: `/${$selectedTeamIdStore}/members`, icon: UserIcon },
    ];
  }

  $: $selectedTeamIdStore, $selectedTeamIdStore !== undefined && updateNavLinks();

  const secondaryNavLinks = [
    { label: "Support", onClick: () => window.alert("Open Intercom Chat"), icon: SupportIcon },
    { label: "Settings", path: "/settings", icon: SettingsIcon },
  ];

  const navLinkClass =
    "nav-item flex items-center gap-3 !font-semibold !px-3 !py-2 -mx-2 [&>svg]:text-gray-700 [&>svg]:stroke-[2px] !text-black dark:!text-white !text-base !justify-start rounded-lg";

  let email = $userStore?.email ?? "";
  $: displayName = $userStore?.preferences?.name || $userStore?.displayName || "";

  const logout = async () => {
    await firebase.auth().signOut();
  };

  let mobileNavOpen = false;

  const toggleMobileNav = () => {
    mobileNavOpen = !mobileNavOpen;
  };
</script>

<div class="sticky top-0 hidden lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col border-r border-general h-[100svh]">
  <nav class="h-full flex flex-col px-6 py-8 overflow-auto max-h-screen">
    <div class="flex h-6 w-full max-w-[142px] justify-start items-start">
      <Logo />
    </div>
    <div class="border-t border-b border-general mt-6 pt-2 mb-4 pb-2 relative flex flex-col">
      <TeamSwitcher />
    </div>

    {#key $selectedTeamIdStore}
      <div class="flex-1 flex flex-col gap-2">
        {#if $selectedTeamIdStore !== undefined && $selectedTeamIdStore !== ""}
          {#each navLinks as { label, path, icon: Icon }, index (label)}
            <a href={path} use:link use:active class={navLinkClass}>
              <Icon />
              {label}
            </a>
          {/each}
        {/if}
      </div>
    {/key}
    <div class="flex flex-col gap-2 mt-8">
      {#each secondaryNavLinks as { label, path, onClick, icon: Icon }, index (label)}
        {#if onClick}
          <button class={navLinkClass} on:click={onClick}>
            <Icon />
            {label}
          </button>
        {:else}
          <a href={path} use:link use:active class={navLinkClass}>
            <Icon />
            {label}
          </a>
        {/if}
      {/each}
    </div>
    <div class="border-t border-general mt-6 pt-6 flex items-center gap-3">
      <div class="flex-1 text-sm overflow-hidden">
        <div class="font-semibold">{displayName}</div>
        <div class="truncate">{email}</div>
      </div>
      <div class="[&>button>svg]:w-4 h-4 self-baseline mt-1 text-gray-700 hover:text-gray-800 cursor-pointer">
        <Button variant="ghost" on:click={logout}>
          <LogoutIcon />
        </Button>
      </div>
    </div>
  </nav>
</div>

<nav
  class="lg:hidden py-6 px-6 border-b border-general sticky top-0 bg-background-light dark:bg-background-dark z-50 flex justify-between items-center gap-4"
>
  <button class="[&>svg]:w-6" on:click={toggleMobileNav}>
    {#if mobileNavOpen}
      <XIcon />
    {:else}
      <Hamburger />
    {/if}
  </button>
  <div class="flex-1 flex h-6 w-full max-w-[142px] justify-center items-start">
    <Logo />
  </div>
  <div class="w-6" />
</nav>

<div
  class={cn(
    "h-[calc(100svh-73px)] fixed top-[73px] flex flex-col w-[60%] p-4 bg-background-light dark:bg-background-dark z-50 transition-all duration-300 border-r border-general",
    mobileNavOpen ? "left-0 before:!opacity-100 before:delay-200" : "-left-full",
    "before:pointer-events-none before:opacity-0 before:transition-opacity before:delay-100 before:duration-200 before:content-[''] before:bg-black/50 before:w-[200vw] before:h-full before:absolute before:top-0 before:left-full",
  )}
>
  <div class="border-b border-general pt-2 mb-4 pb-2 relative flex flex-col">
    <TeamSwitcher />
  </div>
  {#key $selectedTeamIdStore}
    <div class="flex-1 flex flex-col gap-2">
      {#if $selectedTeamIdStore !== undefined && $selectedTeamIdStore !== ""}
        {#each navLinks as { label, path, icon: Icon }, index (label)}
          <a href={path} use:link use:active class={navLinkClass}>
            <Icon />
            {label}
          </a>
        {/each}
      {/if}
    </div>
  {/key}
  <div class="flex flex-col gap-2 mt-8">
    {#each secondaryNavLinks as { label, path, onClick, icon: Icon }, index (label)}
      {#if onClick}
        <button class={navLinkClass} on:click={onClick}>
          <Icon />
          {label}
        </button>
      {:else}
        <a href={path} use:link use:active class={navLinkClass}>
          <Icon />
          {label}
        </a>
      {/if}
    {/each}
  </div>
  <div class="border-t border-general mt-6 pt-6 flex items-center gap-3">
    <div class="flex-1 text-sm overflow-hidden">
      <div class="font-semibold">{displayName}</div>
      <div class="truncate">{email}</div>
    </div>
    <div class="[&>button>svg]:w-4 h-4 self-baseline mt-1 text-gray-700 hover:text-gray-800 cursor-pointer">
      <Button variant="ghost" on:click={logout}>
        <LogoutIcon />
      </Button>
    </div>
  </div>
</div>
