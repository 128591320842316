<script lang="ts">
  import { Button } from "$ui/button";
  import { deleteAPIKey, getAPIKeys, getPermission } from "./utils/resources";
  import * as AlertDialog from "$ui/alert-dialog";
  import * as Dialog from "$ui/dialog";
  import * as Tooltip from "$ui/tooltip";
  import * as DropdownMenu from "$ui/dropdown-menu";
  import { LoadingSpinner } from "$ui/loading-spinner";

  import { formatDateTime, formatDateToHumanReadable } from "./utils/helpers";
  import { cn } from "$utils/ui";

  import DotsIcon from "$assets/dots.svelte";
  import BinIcon from "$assets/bin.svelte";

  import RegenIcon from "$assets/refresh.svelte";
  import GenerateKey from "./ui/generate-key/generate-key.svelte";

  import InfoCircle from "$assets/info-circle.svelte";

  export let appId: string;
  export let keys: string | any[] = [];

  let generateKeyOpen = false;
  let confirmDeleteOpen = false;
  let selectedIndex: any = null;

  let isLoadingKeys = true;
  let isDeleting = false;
  let errorMessage = "";

  $: getAPIKeys(appId, (newKeys: string | any[]) => {
    keys = newKeys;
    isLoadingKeys = false;
  });

  const onGenerateKey = () => {
    generateKeyOpen = true;
  };

  const onRegenerateKeyConfirm = (keyIndex: number) => {
    generateKeyOpen = true;
    selectedIndex = keyIndex;
  };

  const onDeleteKeyConfirm = async (keyIndex: number) => {
    confirmDeleteOpen = true;
    selectedIndex = keyIndex;
  };

  const onDeleteKey = async (keyIndex: string) => {
    try {
      isDeleting = true;
      await deleteAPIKey({ app_id: appId, keyIndex });
      confirmDeleteOpen = false;
    } catch (error: any) {
      errorMessage = "Error deleting API key. " + error?.message;
      console.error("Error deleting API key:", error);
    } finally {
      isDeleting = false;
    }
  };

  const hasPermission = getPermission("app_keys:write");
</script>

<div class="flex-1 border-t border-general pt-3 flex flex-col gap-3">
  {#if isLoadingKeys}
    <div class="flex flex-col items-center gap-3 [&>svg]:w-6">
      <LoadingSpinner />
    </div>
  {:else if keys.length === 0}
    <div class="flex flex-col gap-3">
      <div class="font-semibold text-sm">API Keys</div>
      <p class="text-sm">No API keys generated</p>
    </div>
  {:else}
    <div class="flex flex-col gap-2">
      <div class="grid grid-cols-[0.75fr_1fr_1fr_0.75fr_20px] gap-3 text-sm">
        <div class="font-semibold whitespace-nowrap">API Keys</div>
        <div class="font-semibold whitespace-nowrap">Expiry</div>
        <div class="font-semibold whitespace-nowrap">Modified</div>
        <div class="font-semibold whitespace-nowrap">By</div>
        <div />
      </div>
      <div class="flex flex-col">
        {#each keys as key, index}
          <div class="grid grid-cols-[0.75fr_1fr_1fr_0.75fr_20px] items-center gap-3 text-sm h-[30px]">
            <div class="flex items-center gap-2">
              <p class="min-w-[32px] whitespace-nowrap">Key {index + 1}</p>
              <div class="flex gap-1 overflow-hidden">
                <Tooltip.Root>
                  <Tooltip.Trigger>
                    <p class="[&>svg]:w-3 [&>svg]:h-3 mt-1">
                      <InfoCircle />
                    </p>
                  </Tooltip.Trigger>
                  <Tooltip.Content class="flex flex-col gap-2 py-3">
                    <div class="font-semibold">Key {index + 1}</div>
                    <div class="flex-1 flex flex-col gap-2">
                      <div class="flex justify-between gap-2">
                        <span class="font-medium">Creator:</span>
                        {key.data().creator}
                      </div>
                      <div class="flex justify-between gap-2">
                        <span class="font-medium">Expiry Date: </span>
                        {formatDateTime(key.data().expiry, false)}
                      </div>
                      <div class="flex justify-between gap-2">
                        <span class="font-medium">Modified Date: </span>
                        {formatDateTime(key.data().modified, false)}
                      </div>
                    </div>
                  </Tooltip.Content>
                </Tooltip.Root>
              </div>
            </div>
            <p class="">{formatDateTime(key.data().expiry, false)}</p>
            <p class="truncate">{formatDateToHumanReadable(key.data().modified.seconds)}</p>
            <p class="truncate">{key.data().creator.substring(0, key.data().creator.indexOf("@"))}</p>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger asChild let:builder>
                <Button
                  data-testid="app-key-menu-trigger"
                  variant="ghost"
                  builders={[builder]}
                  class="[&>svg]:rotate-90 [&>svg]:w-3 [&>svg]:h-3 h-[24px]"
                  size="xs"
                >
                  <DotsIcon />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content class="w-40" side="right" align="start">
                <DropdownMenu.Group>
                  <DropdownMenu.Item
                    data-testid="regenerate-menu-item"
                    on:click={() => onRegenerateKeyConfirm(index + 1)}
                    Icon={RegenIcon}
                    disabled={!$hasPermission}
                  >
                    <span>Regenerate</span>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    class="!text-danger-400"
                    on:click={() => onDeleteKeyConfirm(index + 1)}
                    Icon={BinIcon}
                    disabled={!$hasPermission}
                  >
                    <span>Delete</span>
                  </DropdownMenu.Item>
                </DropdownMenu.Group>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </div>
        {/each}
      </div>
    </div>
  {/if}

  <div class={cn("flex-1 flex items-end", keys.length >= 2 && "hidden")}>
    <Button
      data-testid="generate-key-button"
      variant="link"
      size="sm"
      class="font-medium !py-0 !px-0 !h-auto flex text-center items-center gap-1 [&>svg]:w-4"
      on:click={onGenerateKey}
      disabled={keys.length >= 2 || !$hasPermission}
    >
      Generate Key
    </Button>
  </div>
</div>

<!-- Generate/Re-Generate Key -->
<Dialog.Root
  open={generateKeyOpen}
  onOpenChange={() => {
    if (generateKeyOpen) {
      generateKeyOpen = false;
    }
  }}
>
  <Dialog.Content class="max-w-2xl">
    <Dialog.Header>
      <Dialog.Title>Generate an API Key</Dialog.Title>
      <Dialog.Description>
        On Generation this API key will be displayed once and not shown again.

        <br />

        Additionally, you have the option to set an expiry date; otherwise, it will automatically default to 30 days
        from generation.
      </Dialog.Description>
    </Dialog.Header>

    <div class="mt-4 mb-4">
      <GenerateKey app_id={appId} keyIndex={selectedIndex ?? keys.length + 1} variant="default" />
    </div>

    <Dialog.Footer>
      <Button on:click={() => (generateKeyOpen = false)} variant="outline">Done</Button>
    </Dialog.Footer>
  </Dialog.Content>
</Dialog.Root>

<!-- Delete Confirm -->
<AlertDialog.Root
  open={confirmDeleteOpen}
  closeOnOutsideClick={true}
  onOpenChange={(isOpen) => {
    if (!isOpen) {
      confirmDeleteOpen = false;
    }
  }}
>
  <AlertDialog.Content>
    <AlertDialog.Header>
      <AlertDialog.Title>Are You Sure?</AlertDialog.Title>
      <AlertDialog.Description>Are you sure you want to delete this key?</AlertDialog.Description>
    </AlertDialog.Header>
    {#if errorMessage}
      <div class="text-danger-400 text-sm">{errorMessage}</div>
    {/if}
    <AlertDialog.Footer>
      <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
      <Button variant="destructive" on:click={() => onDeleteKey(selectedIndex)} disabled={isDeleting}
        >{isDeleting ? "Deleting..." : "Delete Key"}</Button
      >
    </AlertDialog.Footer>
  </AlertDialog.Content>
</AlertDialog.Root>
