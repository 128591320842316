import { writable } from "svelte/store";
import type { UserInfo } from "firebase/auth";
import type { Firestore } from "firebase/firestore";

type User = {
  preferences: {
    name?: string;
    default_team?: string;
    primary_email?: string;
  };
} & UserInfo;

export interface Env {
  FIREBASE_API_KEY: string;
  FIREBASE_AUTH_DOMAIN: string;
  APP_BASE_URI: string;
}

export enum AuthState {
  Unauthenticated,
  Authenticated,
  Authenticating,
}

export interface Environment {
  environment: string;
  apiRoots: {
    management: string;
    integration: string;
    search: string;
  };
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  legals: {
    termsOfService: string;
    privacyPolicy: string;
  };
}

export const authState = writable<AuthState | null>(null);
export const userStore = writable<User | null>(null);
export const envStore = writable<Environment | null>(null);
export const dbStore = writable<Firestore | null>(null);

export const teamsStore = writable([]);
export const selectedTeamIdStore = writable("");

export const appKeyStore = writable("");
