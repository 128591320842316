<svg
  clip-rule="evenodd"
  fill-rule="evenodd"
  image-rendering="optimizeQuality"
  shape-rendering="geometricPrecision"
  text-rendering="geometricPrecision"
  viewBox="0 -1 1271 1324"
  xmlns="http://www.w3.org/2000/svg"
  ><path
    d="M454 27C247 43 71 57 63 58 40 63 19 78 9 99l-9 18 1 434 1 434 13 27c8 15 58 85 113 156 109 142 117 149 158 154 12 1 103-3 200-9 98-6 256-16 350-21 403-25 382-23 406-43 29-24 26 16 28-512 1-447 0-477-7-490-7-16-21-26-205-155C935 5 926 1 871 0c-23-1-211 12-417 27z"
    fill="#fff"
  /><path
    d="M454 27C247 43 71 57 63 58 40 63 19 78 9 99l-9 18 1 434 1 434 13 27c8 15 58 85 113 156 109 142 117 149 158 154 12 1 103-3 200-9 98-6 256-16 350-21 403-25 382-23 406-43 29-24 26 16 28-512 1-447 0-477-7-490-7-16-21-26-205-155C935 5 926 1 871 0c-23-1-211 12-417 27zm465 57c18 8 145 96 163 113 5 5 7 10 4 12-5 5-792 52-815 48-10-1-25-7-34-13-36-25-125-98-125-103 0-14-3-14 347-40 67-4 178-12 245-18 145-11 188-11 215 1zm250 226c6 6 11 17 13 28 1 10 2 196 1 412-1 370-2 394-9 404-4 7-12 14-19 16-17 7-841 54-858 49-8-2-19-9-24-15l-10-10-1-400c-1-281 0-405 3-416 2-8 9-18 14-22 7-5 56-9 224-19 118-6 301-17 405-24 250-15 249-15 261-3z"
  /><path
    d="M948 447c-45 3-85 7-89 10-14 7-22 19-24 32-1 13 3 15 48 21l19 2v177c0 105-1 174-4 172-2-2-60-92-130-198-70-108-128-196-129-196-1-1-46 1-101 5-67 4-104 9-111 13-12 6-26 28-26 42 0 9 16 14 49 14h18v508l-29 9c-21 6-29 10-33 19-6 14-6 26 1 26 2 0 49-2 103-6 106-6 118-9 129-31 4-6 7-13 7-16 0-1-15-6-32-10-18-4-36-9-40-9-7-2-7-15-7-193V647l126 197c132 207 148 231 169 241 25 13 89 4 121-17l10-6 1-282 1-283 22-4c26-5 38-17 38-38 0-13-1-14-13-13-7 0-50 3-94 5z"
  /></svg
>
