<script lang="ts">
  import { Form } from "$ui/form";
  import { Button } from "$ui/button";
  import { cn } from "$utils/ui";
  import { SearchBar } from "$ui/search-bar";

  import { DATA_SOURCES } from "$utils/constants";
  import { fuzzyMatch } from "$utils/helpers";

  let filteredDataSources = DATA_SOURCES;

  function handleSearchChange(newTerm: string) {
    filteredDataSources = newTerm.trim()
      ? DATA_SOURCES.filter(
          ({ title, subtitle }) => fuzzyMatch(title, newTerm.trim()) || fuzzyMatch(subtitle, newTerm.trim()),
        )
      : DATA_SOURCES;
  }

  const initialValues = {
    data_sources: [],
  };

  const onSubmit = async (values: any) => {
    console.log("values", values);
  };

  let standaloneForm: boolean | undefined;
  let onSuccess: () => void;

  let form: any;
  let touched: any;
  let isSubmitting: any;
  let handleSubmit: () => void;

  let validateField: (field?: any) => any;
  let updateValidateField: (field?: any, value?: any) => void;

  const emptyErrors: any = {};

  function handleDataSourceChange(value: string) {
    return $form.data_sources.includes(value)
      ? $form.data_sources.filter((source: string) => source !== value)
      : $form.data_sources.concat(value);
  }

  export { form, touched, isSubmitting, handleSubmit, standaloneForm, onSuccess };
</script>

<div class="flex-1 flex flex-col items-stretch gap-6">
  <div class="sticky top-0 bg-background-light dark:bg-background-dark" id="search-bar">
    <SearchBar onChange={handleSearchChange} />
  </div>

  <Form
    {initialValues}
    {onSubmit}
    {onSuccess}
    {standaloneForm}
    errors={emptyErrors}
    validationSchema={null}
    bind:form
    bind:handleSubmit
    bind:validateField
    bind:updateValidateField
    bind:isSubmitting
    bind:touched
  >
    {#if $form}
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        {#each filteredDataSources as { value, title, subtitle, icon: Icon, preview }}
          <label
            for={value}
            class="relative overflow-hidden group w-full h-40 card p-6 bg-white flex items-stretch cursor-pointer select-none transition-colors duration-300"
          >
            <div class="flex-1 flex flex-col justify-between">
              {#if preview}
                <div class="absolute top-5 -left-11 py-0 bg-yellow-600 text-white px-12 -rotate-45"
                  title="This data source is in preview, which means that it might not work as expected.">
                  Preview
                </div>
              {/if}
              {#key value}
                <div
                  class="h-12 w-12 bg-gray-50 dark:bg-transparent border border-general rounded-full flex justify-center items-center [&>svg]:w-7 [&>svg]:h-7"
                >
                  <Icon />
                </div>
              {/key}
              <div>
                <div class="font-semibold">
                  {title}
                </div>
                <div class="text-sm p">
                  {subtitle}
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-between items-end">
              <input
                type="checkbox"
                id={value}
                name={value}
                {value}
                checked={$form.data_sources.includes(value)}
                on:change={() => updateValidateField("data_sources", handleDataSourceChange(value))}
              />
              <Button
                variant="outline"
                size="sm"
                class={cn(
                  "pointer-events-none transition-all duration-150",
                  $form.data_sources.includes(value) && "opacity-0",
                )}
              >
                Select
              </Button>
            </div>
          </label>
        {/each}
      </div>
    {/if}
  </Form>
</div>
