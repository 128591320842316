<script lang="ts">
  import { push } from "svelte-spa-router";

  import Shell from "$lib/Shell.svelte";
  import { Button } from "$ui/button";

  import PlusCircle from "$assets/plus-circle.svelte";
</script>

<Shell class="flex flex-col  gap-10">
  <div class="flex flex-col gap-1">
    <h1 class="text-3xl font-semibold">Not Found!</h1>
    <p class="text-lg">Oops, it looks like you've hit a snag.</p>
  </div>
  <div class="flex-1 flex items-center justify-center mb-24">
    <div class="flex-1 flex flex-col gap-6 justify-center items-center p-8 card max-w-[720px]">
      <div class="flex flex-col gap-4 text-center justify-center items-center">
        <h1 class="text-2xl font-semibold">Access Unavailable!</h1>
        <p class="text-lg">
          Either you do not have access to this team, or it may not exist.

          <br />
          <br />
          If you're certain it's out there, contact the team admin to invite you. Or alternatively you can create a new team
          or select another team in the navigation.
        </p>
      </div>
      <Button on:click={() => push("/onboarding")} class="gap-2 [&>svg]:w-5">
        <PlusCircle />
        Create New Team
      </Button>
    </div>
  </div>
</Shell>
