<script lang="ts">

import { GenerateKey } from "$ui/generate-key";
import { PasteKey } from "$ui/paste-key";
import { getAPIKeys } from "$utils/resources";

export let hideGenerateKey = false;
export let app_id = "";

let keys: string[] = [];
  $: getAPIKeys(app_id, (newKeys: any) => {
    keys = newKeys;
  });

</script>



<div class="flex flex-col gap-4">
  {#if !hideGenerateKey}
      <div>
        <h3 class="font-semibold">Generate API key</h3>
        <p>This is the only time that this API Key can be viewed or downloaded. You can create more keys later.</p>
      </div>
      <GenerateKey {app_id} />
      {#if keys.length + 1 > 2}
        <div class="text-sm text-danger-700 -mt-2">
          This app has the maximum of 2 API keys already, please remove one to generate another
        </div>
      {/if}
  {:else}
    <PasteKey {app_id} />
  {/if}
</div>
