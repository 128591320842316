<script lang="ts">
  import { getAPIKeys } from "$utils/resources";
  import GenerateKey from "$ui/generate-key/generate-key.svelte";

  import { CodeBlock } from "$ui/code-block";
  import { CODE_BLOCKS_INSTALL, CODE_BLOCKS_SDK_INIT } from "./utils/constants";
  import "highlight.js/styles/github.css";
  import ApiKeyHelper from "./ApiKeyHelper.svelte";
  import { appKeyStore } from "./utils/stores";

  let data_sources: [] = [];
  let app_id = "";
  let exampleKey = "test_1234567"
  let hideGenerateKey = false;

  let keys: [] = [];
  $: getAPIKeys(app_id, (newKeys: any) => {
    keys = newKeys;
  });

  $: codeBlocks = CODE_BLOCKS_SDK_INIT(data_sources, $appKeyStore || exampleKey);

  let onSuccess: (values: any) => Promise<void>;

  const handleSubmit = async () => {
    const values = {
      $appKeyStore,
    };
    onSuccess && (await onSuccess(values));
  };

  export { data_sources, app_id, hideGenerateKey, handleSubmit, onSuccess };
</script>

<div class="flex flex-col gap-4">
  <div class="flex flex-col gap-6">
    <ApiKeyHelper {hideGenerateKey} />
    <div class="flex flex-col gap-4">
      <div>
        <h3 class="font-semibold">Install package</h3>
        <p>Run this command to install the latest version of our SDK for your backend application.</p>
      </div>
      <CodeBlock blocks={CODE_BLOCKS_INSTALL} />
    </div>
    <div class="flex flex-col gap-4">
      <div>
        <h3 class="font-semibold">Initialize SDK client</h3>
        <p>Then, initialize your client with Redactive using your app key.</p>
      </div>
      <CodeBlock blocks={codeBlocks} />
    </div>
  </div>
</div>
