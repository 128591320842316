<script lang="ts">
  import { CodeBlock } from "$ui/code-block";
  import { CODE_BLOCKS_CAPTURE_CALLBACK, CODE_BLOCKS_CONNECT_DS, CODE_BLOCKS_REFRESH_TOKEN } from "./utils/constants";
  import "highlight.js/styles/github.css";
  import ApiKeyHelper from "./ApiKeyHelper.svelte";
  import { appKeyStore } from "./utils/stores";

  let data_sources: string[] = [];
  let app_id = "";
  let hideGenerateKey = false;
  let redirect_uri = "http://localhost:8000";

  let exampleKey = "test_1234567"

  $: endpoint = data_sources.includes("zendesk") ? "getZendeskEndpoint()" : "\"\"";

  $: captureCallbackBlocks = CODE_BLOCKS_CAPTURE_CALLBACK(data_sources, $appKeyStore || exampleKey);

  $: connectDsBlocks = CODE_BLOCKS_CONNECT_DS(data_sources, $appKeyStore || exampleKey, redirect_uri, endpoint);

  $: refreshTokenBlocks = CODE_BLOCKS_REFRESH_TOKEN(data_sources, $appKeyStore || exampleKey);

  let onSuccess: (values: any) => Promise<void>;

  const handleSubmit = async () => {
    const values = {
      $appKeyStore,
    };
    onSuccess && (await onSuccess(values));
  };

  export { data_sources, app_id, hideGenerateKey, handleSubmit, onSuccess };
</script>

<div class="flex flex-col gap-4">
  <div class="flex flex-col gap-6">
    <ApiKeyHelper {hideGenerateKey} {app_id} />
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <h3 class="font-semibold">Setup your frontend so users can connect datasources</h3>
        <p>Create a method for your users to connect their datasources. This method should be attached to a user action in your app such as "Connect Datasource"</p>
        <p>This method will redirect users away from your app to sign into the selcted datasource using OAuth.</p>
      </div>
      <CodeBlock blocks={connectDsBlocks} />
    </div>
    <div class="flex flex-col gap-4">
      <div>
        <h3 class="font-semibold">Capture Callback</h3>
        <p>Redactive will redirect users back to the <code>redirect_url</code> specified in your connect datasource call.</p>
        <p>You must capture the callback and store the access token, this will be used later to make queries on behalf of your users.</p>
      </div>
      <CodeBlock blocks={captureCallbackBlocks} />
      <p>You may optionally store the refresh token and use it to gain another access token when the current one expires.</p>
    </div>
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <h3 class="font-semibold">Handling expired Access Tokens</h3>
        <p>Using the same API endpoint you can exchange a refresh token for another access token and refresh token pair.</p>
      </div>
      <CodeBlock blocks={refreshTokenBlocks} />
    </div>
  </div>
</div>
