<script lang="ts">
  import { link, location, push } from "svelte-spa-router";
  import { selectedTeamIdStore, teamsStore } from "$utils/stores";

  import { cn } from "$utils/ui";
  import { Button } from "$ui/button";
  import * as Command from "$ui/command";
  import * as Dialog from "$ui/dialog";
  import * as Popover from "$ui/popover";

  import TeamIcon from "$assets/team.svelte";
  import ArrowUpDownIcon from "$assets/arrow-up-down.svelte";
  import PlusCircle from "$assets/plus-circle.svelte";
  import TickIcon from "$assets/tick.svelte";

  let teams: any[];
  let selectedTeam: { alias: any; id: any } | null;

  $: teams = $teamsStore;
  $: selectedTeam = $teamsStore.find((team: any) => team.id === $selectedTeamIdStore) ?? null;

  const urlParts = $location.split("/");
  const currentRoute = urlParts[2] ?? "";

  let open = false;
  let showTeamDialog = false;

  function closeAndRefocusTrigger() {
    open = false;
  }
</script>

<Dialog.Root bind:open={showTeamDialog}>
  <Popover.Root bind:open let:ids>
    <Popover.Trigger asChild let:builder>
      <Button
        builders={[builder]}
        variant="ghost"
        role="combobox"
        aria-expanded={open}
        aria-label="Select a team"
        class="border-0 text-black dark:text-white text-base flex justify-between items-center gap-3 font-semibold px-3 py-2 -mx-2 "
      >
        <div class="text-gray-700 [&>svg]:w-6">
          <TeamIcon />
        </div>
        <div class="flex-1 text-left text-ellipsis overflow-hidden">
          {selectedTeam?.alias ?? "Select a team"}
        </div>
        <div class="flex-shrink-0 hover:bg-gray-200 hover:dark:bg-primary/50 p-1 rounded-md text-gray">
          <ArrowUpDownIcon />
        </div>
      </Button>
    </Popover.Trigger>
    <Popover.Content class="w-[240px] p-0">
      <Command.Root>
        <Command.List>
          <Command.Empty>No team found.</Command.Empty>
          <Command.Group heading="Your Teams">
            {#each teams as team}
              <Command.Item
                onSelect={() => {
                  selectedTeam = team;
                  closeAndRefocusTrigger();
                  push(`/${team.id}/${currentRoute}`);
                }}
                value={team.id}
                class={cn("text-sm", selectedTeam?.id === team.id && "popover-item-active")}
              >
                <div class="flex-1 flex flex-col font-medium">
                  {team.alias}
                  <p class="text-xs font-normal">
                    {team.id}
                  </p>
                </div>
                {#if selectedTeam?.id === team.id}
                  <div>
                    <TickIcon />
                  </div>
                {/if}
              </Command.Item>
            {/each}
          </Command.Group>
        </Command.List>
        <Command.Separator />
        <Command.List>
          <Command.Group>
            <Command.Item class="border-t border-general p-0">
              <a
                href="/onboarding"
                use:link
                class="flex items-center leading-tight gap-2 text-sm font-medium [&>svg]:text-gray-700 [&>svg]:w-5 p-2 w-full"
              >
                <PlusCircle />
                Create New Team
              </a>
            </Command.Item>
          </Command.Group>
        </Command.List>
      </Command.Root>
    </Popover.Content>
  </Popover.Root>
</Dialog.Root>
