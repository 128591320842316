<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M21.1877 11.1249C22.3957 11.1249 23.375 10.1456 23.375 8.93756C23.375 7.72954 22.3956 6.75025 21.1876 6.75025C19.9795 6.75025 19 7.72962 19 8.93775V11.1249H21.1877ZM15.0625 11.1249C16.2706 11.1249 17.25 10.1455 17.25 8.93737V2.81274C17.25 1.60462 16.2706 0.625244 15.0625 0.625244C13.8544 0.625244 12.875 1.60462 12.875 2.81274V8.93737C12.875 10.1455 13.8544 11.1249 15.0625 11.1249Z"
    fill="#2EB67D"
  />
  <path
    d="M2.81231 12.8751C1.60429 12.8751 0.625001 13.8544 0.625001 15.0624C0.625001 16.2705 1.60439 17.2498 2.81241 17.2498C4.02053 17.2498 5 16.2704 5 15.0623V12.8751H2.81231ZM8.9375 12.8751C7.72938 12.8751 6.75 13.8545 6.75 15.0626V21.1873C6.75 22.3954 7.72938 23.3748 8.9375 23.3748C10.1456 23.3748 11.125 22.3954 11.125 21.1873V15.0626C11.125 13.8545 10.1456 12.8751 8.9375 12.8751Z"
    fill="#E01E5A"
  />
  <path
    d="M12.8754 21.1877C12.8754 22.3957 13.8547 23.375 15.0627 23.375C16.2707 23.375 17.25 22.3956 17.25 21.1876C17.25 19.9795 16.2706 19 15.0625 19L12.8754 19L12.8754 21.1877ZM12.8754 15.0625C12.8754 16.2706 13.8548 17.25 15.0629 17.25L21.1875 17.25C22.3956 17.25 23.375 16.2706 23.375 15.0625C23.375 13.8544 22.3956 12.875 21.1875 12.875L15.0629 12.875C13.8548 12.875 12.8754 13.8544 12.8754 15.0625Z"
    fill="#ECB22E"
  />
  <path
    d="M11.1246 2.81231C11.1246 1.60429 10.1453 0.625001 8.93731 0.625001C7.72929 0.625001 6.75 1.60439 6.75 2.81241C6.75 4.02053 7.72938 5 8.9375 5L11.1246 5L11.1246 2.81231ZM11.1246 8.9375C11.1246 7.72938 10.1452 6.75 8.93712 6.75L2.8125 6.75C1.60438 6.75 0.625 7.72938 0.625 8.9375C0.625 10.1456 1.60438 11.125 2.8125 11.125L8.93713 11.125C10.1452 11.125 11.1246 10.1456 11.1246 8.9375Z"
    fill="#36C5F0"
  />
</svg>
