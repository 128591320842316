<script lang="ts">
  import type { HTMLTextareaAttributes } from "svelte/elements";

  import { Field } from "$ui/field";
  import { cn } from "$utils/ui";

  type $$Props = HTMLTextareaAttributes & {
    label?: string;
    error?: string;
    type?: string;
  };

  let className: $$Props["class"] = undefined;
  export let value: $$Props["value"] = undefined;
  export { className as class };

  export let label: $$Props["label"] = undefined;
  export let error: $$Props["error"] = undefined;
  export let type: $$Props["type"] = undefined;
</script>

<Field {label} {error} {type}>
  <textarea
    class={cn("field-input flex min-h-[80px] w-full rounded-md px-3 py-2 text-sm", className)}
    bind:value
    on:blur
    on:change
    on:click
    on:focus
    on:keydown
    on:keypress
    on:keyup
    on:mouseover
    on:mouseenter
    on:mouseleave
    on:paste
    on:input
    {...$$restProps}
  />
</Field>
