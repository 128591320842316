import Root from "./button.svelte";

import { tv } from "tailwind-variants";

const hoverUnderline =
  " after:w-0 hover:after:w-full after:right-0 hover:after:left-0 relative after:h-[1.5px] after:absolute after:bottom-0 after:duration-300";

export const buttonVariants = tv({
  base: "inline-flex items-center justify-center rounded-lg text-sm font-semibold subpixel-antialiased whitespace-nowrap ring-offset-background transition-colors duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-70",
  variants: {
    variant: {
      default: "bg-primary text-white hover:bg-primary/90",
      destructive: "bg-danger text-white hover:bg-danger-600",
      outline:
        "text-gray-600 dark:text-white border border-gray-300 dark:border-primary-700 bg-background-light dark:bg-background-dark hover:!bg-gray-100 hover:dark:!bg-primary/50 group-hover:bg-gray-100 group-hover:dark:bg-primary/50",
      ghost: "text-gray dark:text-white hover:bg-gray-100 hover:dark:bg-white/10 font-medium -mr-2 p-2",
      link: `text-primary dark:text-primary-700 underline-offset-4 !px-0 !py-0 !h-auto after:bg-current ${hoverUnderline}`,
    },
    size: {
      default: "h-10 px-4 py-2",
      xs: "h-9 rounded-lg p-1 px-3 text-xs",
      sm: "h-9 rounded-lg px-3 text-sm",
      lg: "h-11 rounded-lg px-8 text-sm",
      icon: "h-10 w-10",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

export { Root, Root as Button };
