<script lang="ts">
  import { onMount } from "svelte";
  import { setMember } from "$utils/resources";

  import * as yup from "yup";
  import { Form } from "$ui/form";
  import { Button } from "$ui/button";
  import { Input } from "$ui/input";
  import * as Select from "$ui/select";

  import BinIcon from "$assets/bin.svelte";

  import { isArrayEqual } from "$utils/helpers";
  import { ROLES } from "$utils/constants";
  import { cn } from "$utils/ui";

  type MembersFormProps = {
    initialValues?: { members: { data?: any; email: any; id?: any; claims?: string[] | undefined }[] } | undefined;
    isEdit: boolean;
    onSuccess: () => void;
    form?: any;
    errors?: any;
    touched?: any;
    isSubmitting?: any;
    handleSubmit?: () => void;
    standaloneForm?: boolean;
    validateField?: (field?: any) => any;
    updateValidateField?: () => void;
  };

  let isEdit: boolean = false;

  let initialValues: any = {
    members: [{ email: "", claims: ROLES[0].value }],
  };

  const validationSchema = yup.object().shape({
    members: yup.array().of(
      yup.object().shape({
        email: yup.string().email("Invalid email"),
      }),
    ),
  });

  const onSubmit = async (values: any) => {
    const validMembers = values.members.filter((member: any) => member.email !== "");
    for (const member of validMembers) {
      await setMember({ member_id: member.email, claims: member.claims });
    }
  };

  let standaloneForm: MembersFormProps["standaloneForm"] = true;
  let onSuccess: MembersFormProps["onSuccess"];

  let form: MembersFormProps["form"] = null;
  let errors: MembersFormProps["errors"] = null;
  let touched: MembersFormProps["touched"] = null;
  let isSubmitting: MembersFormProps["isSubmitting"] = false;
  let handleSubmit: MembersFormProps["handleSubmit"] = () => {};

  let validateField: MembersFormProps["validateField"];
  let updateValidateField: MembersFormProps["updateValidateField"];

  onMount(() => {
    $touched.members[0] = { email: true, claims: true };
  });

  const add = () => {
    $form.members = $form.members.concat({ email: "", claims: ROLES[0].value });
    $errors.members = $errors.members.concat({ email: "" });
    $touched.members = $touched.members.concat({ email: true, claims: true });
  };

  const remove = (index: string | number) => () => {
    $form.members = $form.members.filter((_: any, currentIndex: string | number) => currentIndex !== index);
    $errors.members = $errors.members.filter((_: any, currentIndex: string | number) => currentIndex !== index);
  };

  export {
    wizardValues,
    form,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    standaloneForm,
    onSuccess,
    initialValues,
    isEdit,
  };
</script>

<div class="flex-1 flex flex-col items-stretch">
  <Form
    {initialValues}
    {validationSchema}
    {onSubmit}
    {onSuccess}
    {standaloneForm}
    bind:form
    bind:handleSubmit
    bind:errors
    bind:validateField
    bind:updateValidateField
    bind:isSubmitting
    bind:touched
  >
    {#if $form}
      {#each $form.members as member, index}
        <div class="flex flex-col gap-4">
          <div class={cn("flex flex-col md:flex-row gap-4 [&>div]:flex-1", isEdit && "flex-col")}>
            <Input
              name={`members[${index}].email`}
              label="Email"
              placeholder="Email"
              error={($errors.members && $errors.members[index] && $errors.members[index].email) || ""}
              readOnly={isEdit}
              bind:value={member.email}
              on:blur={() => {
                validateField && validateField(`members?[${index}].email`);
              }}
              on:input={() => {
                validateField && validateField(`members[${index}].email`);
              }}
            />
            <Select.Root
              selected={ROLES.find((role) => isArrayEqual(role.value, member.claims))}
              onSelectedChange={(selectedRole) => {
                $form.members[index].claims = selectedRole?.value;
              }}
            >
              <Select.Trigger name="alias" label="Role">
                <Select.Value placeholder="Select a Role" />
              </Select.Trigger>
              <Select.Content>
                <Select.Group>
                  <Select.Label>Roles</Select.Label>
                  {#each ROLES as role}
                    <Select.Item
                      value={role.value}
                      label={role.label}
                      isSelected={isArrayEqual(role.value, member.claims)}
                    >
                      {role.label}
                    </Select.Item>
                  {/each}
                </Select.Group>
              </Select.Content>
            </Select.Root>
            {#if !isEdit}
              <div class="!flex-[0_0_24px] md:mt-9 self-end md:self-stretch">
                <button
                  type="button"
                  on:click={remove(index)}
                  disabled={$form.members.length === 1}
                  class={cn("[&>svg]:w-3.5", $form.members.length === 1 && "opacity-30 cursor-not-allowed")}
                >
                  <BinIcon />
                </button>
              </div>
            {/if}
          </div>
          <div>
            {#if index === $form.members.length - 1 && !isEdit}
              <Button on:click={add} variant="outline" size="xs">+ Add More</Button>
            {/if}
          </div>
        </div>
      {/each}
    {/if}
  </Form>
</div>
