<script lang="ts">
  import { Command as CommandPrimitive } from "cmdk-sv";
  import { cn } from "$utils/ui";
  type $$Props = CommandPrimitive.GroupProps;

  let className: string | undefined | null = undefined;
  export { className as class };
</script>

<CommandPrimitive.Group
  class={cn(
    "overflow-hidden p-1 text-foreground [&_[data-cmdk-group-heading]]:px-2 [&_[data-cmdk-group-heading]]:py-1.5 [&_[data-cmdk-group-heading]]:text-[13px] [&_[data-cmdk-group-heading]]:font-semibold [&_[data-cmdk-group-heading]]:text-gray [&_[data-cmdk-group-heading]]:dark:text-gray-400 [&_[data-cmdk-group-heading]]:mb-2 [&_[data-cmdk-group-heading]]:border-b [&_[data-cmdk-group-heading]]:dark:border-primary",
    className,
  )}
  {...$$restProps}
>
  <slot />
</CommandPrimitive.Group>
