<script lang="ts">
  import { cn } from "$utils/ui";
  import type { HTMLTdAttributes } from "svelte/elements";

  type $$Props = HTMLTdAttributes;

  let className: $$Props["class"] = undefined;
  export { className as class };
</script>

<td
  class={cn(
    "text-[15px] py-2 sm:py-3 px-4 sm:px-6 align-middle [&:has([role=checkbox])]:pr-0",
    className,
    `before:content-[attr(data-label)":"] before:font-semibold before:inline-flex before:!w-[70px]`,
    "relative sm:before:hidden before:mr-4",
    "truncate w-full sm:w-auto pr-12",
  )}
  {...$$restProps}
  on:click
  on:keydown
>
  <slot />
</td>
