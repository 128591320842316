<script lang="ts">
  import { Select as SelectPrimitive } from "bits-ui";
  import { ChevronDown } from "lucide-svelte";
  import { Field } from "$ui/field";
  import { cn } from "$utils/ui";

  type $$Props = SelectPrimitive.TriggerProps & { label?: string };
  type $$Events = SelectPrimitive.TriggerEvents;

  let className: $$Props["class"] = undefined;
  export { className as class };

  // @ts-ignore
  export let label: $$Props["label"] = undefined;
  // @ts-ignore
  export let error: $$Props["error"] = undefined;
</script>

<Field {label} {error}>
  <SelectPrimitive.Trigger
    class={cn(
      "field-input flex h-10 w-full items-center justify-between rounded-md  px-3 py-2 text-sm",
      "focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1",
      className,
    )}
    {...$$restProps}
    let:builder
    on:click
    on:keydown
  >
    <slot {builder} />
    <div>
      <ChevronDown class="h-4 w-4 opacity-50" />
    </div>
  </SelectPrimitive.Trigger>
</Field>
