<script lang="ts">
  import { createForm } from "svelte-forms-lib";
  import { SubmitButton } from "$ui/submit-button";

  interface FormProps {
    initialValues?: any;
    validationSchema?: any;
    onSubmit?: (values: any) => void;
    onSuccess?: (values: any) => void;
    standaloneForm?: boolean;
  }

  export let initialValues: FormProps["initialValues"] = {};
  export let validationSchema: FormProps["validationSchema"];
  export let onSubmit: FormProps["onSubmit"];
  export let onSuccess: FormProps["onSuccess"] = () => {};
  export let standaloneForm: FormProps["standaloneForm"] = true;

  let isFormSubmitting = false;

  const { form, errors, handleSubmit, updateValidateField, isSubmitting, touched, validateField } = createForm({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        onSubmit && (await onSubmit(values));
        onSuccess && onSuccess(values);
      } catch (error) {
        console.error(error);
        errors.set({
          ...$errors,
          general: (error as Error).message || "An error occurred during submission",
        });
      }
    },
  });

  isFormSubmitting = $isSubmitting;

  export { form, errors, touched, validateField, updateValidateField, handleSubmit, isSubmitting };
</script>

<div class="flex-1 flex flex-col items-stretch">
  <form on:submit={handleSubmit} class="flex-1 w-full flex flex-col gap-4 mx-auto">
    <slot />
    {#if standaloneForm}
      <div class="flex-1 flex items-end">
        <SubmitButton {isSubmitting} {errors} {touched}>
          {#if $isSubmitting}
            Saving...
          {:else}
            Save
          {/if}
        </SubmitButton>
      </div>
    {/if}
    {#if $errors.general}
      <small class="text-danger-400">{$errors.general}</small>
    {/if}
  </form>
</div>
