<script lang="ts">
  import { cn } from "$utils/ui";
  import type { HTMLTableAttributes } from "svelte/elements";

  type $$Props = HTMLTableAttributes;

  let className: $$Props["class"] = undefined;
  export { className as class };
</script>

<div class="w-full overflow-hidden">
  <table class={cn("w-full caption-bottom text-sm flex flex-col sm:table", className)} {...$$restProps}>
    <slot />
  </table>
</div>
