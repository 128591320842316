<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 -1 24 24"
  stroke-width="2"
  stroke="currentColor"
  fill="none"
  stroke-linecap="round"
  stroke-linejoin="round"
  ><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path
    d="M9 12h6"
  /><path d="M12 9v6" /></svg
>
