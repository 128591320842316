<script lang="ts">
  import Shell from "$lib/Shell.svelte";

  import { link } from "svelte-spa-router";

  import UserAddIcon from "$assets/user-add.svelte";
  import ChangeIcon from "$assets/change-stat.svelte";
  import AppsIcon from "$assets/apps.svelte";

  import IncreaseChart from "$assets/chart-increase.svelte";
  import DecreaseChart from "$assets/chart-decrease.svelte";

  import { cn } from "$utils/ui";
  import { envStore, selectedTeamIdStore, userStore } from "$lib/utils/stores";
  import { getApps, getMembers } from "$lib/utils/resources";
  import Button from "$lib/ui/button/button.svelte";
  import Logo from "$assets/logo.svelte";
  import LogoGraphic from "$assets/logo-graphic.svelte";
  import ExternalLinkIcon from "$assets/external-link.svelte";
  import BlogIcon from "$assets/blog.svelte";
  import { buttonVariants } from "$lib/ui/button";

  let members: any[] = [];
  let apps: any[] = [];

  $: {
    getMembers((newMembers) => {
      members = newMembers;
    });

    getApps((newApps) => {
      apps = newApps;
    });
  }

  $: displayName = $userStore?.preferences?.name || $userStore?.displayName || "";

  $: usefulLinks = [
    {
      title: "Apps",
      icon: AppsIcon,
      value: apps.length,
      path: { href: `/${$selectedTeamIdStore}/apps`, label: apps.length <= 0 ? "Create First App" : "View Apps" },
    },
    {
      title: "Members",
      icon: UserAddIcon,
      value: members.length,
      path: {
        href: `/${$selectedTeamIdStore}/members`,
        label: "View Members",
      },
    },
  ];

  const blogPosts = [
    {
      title: "You don't want a Vector Database",
      description:
        "All of this interest in Vector Databases is driven by the explosion of Generative AI that ChatGPT sparked (less than 18 months ago!). Specifically, that Vector Databases enable the Retrieval Augmented Generation (RAG) paradigm, which is currently the leading form of prompt engineering and actually extracting business value from Large Language Models (LLMs).",
      link: "https://www.redactive.ai/blog/you-dont-want-a-vector-database",
    },
    {
      title: "Why hasn't AI taken over the Finance Industry?",
      description:
        "AI has already proven to be a revolutionary force around the world. From deciphering dead languages to finding previously unknown patterns in historical data, it is redefining our understanding of our past. With content creation and recommendation systems on platforms we use daily it changes the way we view our present. And with assisting on health diagnosis and the creation of new pharmaceutical drugs it is already changing our shared future. Silicon Valley is also betting big on AI as the most important tech breakthrough since the smartphone. For all of this, AI has yet to be a revolutionary force in the financial services industry (FSI). Despite the huge amount of blogs, articles, and papers dedicated to the subject, as someone who’s job it is to watch for fintech trends around the world I have been extremely underwhelmed by the so called “impact” of AI in financial services. Unlike the humanities, entertainment, and healthcare industries listed above, when you read any article on the impact of AI in finance it usually comes down to a few key areas: credit decisioning, fraud and AML, and (sigh) chatbots.  The lack of imagination is not the author’s faults - there are simply very few real world examples thus far of AI working in the financial industry.  The better question then “what is possible” for AI in finance is “why isn’t finance adopting AI more quickly?”. ",
      link: "https://www.redactive.ai/blog/why-hasnt-ai-taken-over-the-finance-industry",
    },
  ];

  let docsLink =
    `${($envStore as { apiRoots?: { management?: string } })?.apiRoots?.management}/docs` ||
    "https://dashboard.staging.redactive.ai/api/docs";
</script>

<Shell class="flex flex-col gap-10">
  <div class="flex flex-col gap-1">
    <h1 class="font-semibold">Welcome back, {displayName}</h1>
    <p class="text-lg">Let’s start sourcing data. Securely.</p>
  </div>
  <div class="flex-1 flex flex-col gap-16">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
      {#each usefulLinks as { title, icon: Icon, value, path }, index (title)}
        <a class="card p-5 pt-3 pb-5 flex-1 flex flex-col gap-2" href={path.href} use:link>
          <div class="flex gap-3">
            <div class="flex-1 flex items-center gap-3">
              <h3 class="text-xl font-semibold flex-1">{title}</h3>
              <a href={path.href} use:link class={buttonVariants({ variant: "link" })}>
                {path.label}
              </a>
            </div>
          </div>
          <div class="flex items-end">
            <div class="flex-1 flex items-start gap-3">
              <div class="text-5xl font-semibold">{value}</div>
            </div>
            <div
              class="border-0 border-gray-200 rounded-lg [&>svg]:w-11 [&>svg]:h-11 [&>svg]:stroke-[1.5px] text-gray-300"
            >
              <Icon />
            </div>
          </div>
        </a>
      {/each}
    </div>
    <div class="gap-8 xl:gap-16 grid grid-cols-1 md:grid-cols-3">
      <div class="md:col-span-2 flex flex-col gap-4">
        <div class="font-semibold text-lg border-b border-general pb-4 mb-4 flex justify-between items-center">
          <h3>Some helpful guides</h3>
          <Button
            variant="link"
            href="https://www.redactive.ai/blog"
            class="flex items-center gap-2 [&>svg]:w-4"
            target="_blank"
            rel="noreferrer"
          >
            View Blog
            <ExternalLinkIcon />
          </Button>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 h-full">
          {#each blogPosts as { title, description, link }, index (title)}
            <a class="h-full card flex flex-col overflow-hidden" href={link} target="_blank" rel="noreferrer noopener">
              <div class="flex-1 p-5 flex flex-col">
                <div class="[&>svg]:w-7">
                  <BlogIcon />
                </div>
                <h3 class="mt-5 text-lg font-semibold line-clamp-2">{title}</h3>
                <p class="mt-4 mb-6 line-clamp-3">{description}</p>
                <div class="flex-1 flex items-end">
                  <Button
                    variant="link"
                    size="lg"
                    href={link}
                    class="flex items-center gap-2 [&>svg]:w-4"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Read more
                    <ExternalLinkIcon />
                  </Button>
                </div>
              </div>
            </a>
          {/each}
        </div>
      </div>
      <div class="flex-1 flex flex-col gap-4">
        <div class="font-semibold text-lg border-b border-general pb-4 mb-4">Helpful docs</div>
        <div class="h-full">
          <a
            class="h-full card p-5 [&>svg]:w-8 flex flex-col"
            href={docsLink}
            target="_blank"
            rel="noreferrer noopener"
          >
            <LogoGraphic />
            <div class="mt-6 text-lg font-semibold">Learn about Redactive</div>
            <p class="flex-1 mt-4 mb-6">
              Get detailed information on how Redactive ensures seamless integration and maintains high security
              standards.
            </p>
            <Button class="self-start flex items-center gap-2 [&>svg]:w-4" variant="link" size="lg">
              View Docs
              <ExternalLinkIcon />
            </Button>
          </a>
        </div>
      </div>
    </div>
  </div>
</Shell>
