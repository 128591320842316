<script lang="ts">
  import SearchIcon from "$assets/search.svelte";
  export let onChange: (term: string) => void;
</script>

<div class="flex-1 my-2 relative">
  <div
    class="[&>svg]:absolute [&>svg]:w-5 [&>svg]:h-5 [&>svg]:top-1/2 [&>svg]:-translate-y-1/2 [&>svg]:left-4 [&>svg]:text-gray"
  >
    <SearchIcon />
  </div>
  <input
    type="text"
    placeholder="Search"
    on:input={(event) => onChange(event.target.value)}
    class="w-full px-3.5 pl-11 py-2.5 rounded-lg field-input"
  />
</div>
