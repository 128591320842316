<script lang="ts">
  import { cn } from "$utils/ui";

  export let label: string | undefined;
  export let error: string | undefined;
  export let type = "text";
</script>

<div class={cn(type === "hidden" && "hidden")}>
  {#if label}
    <div class="mb-2 text-sm font-semibold">{label}</div>
  {/if}

  <slot />

  {#if error}
    <small class="text-danger-400">{error}</small>
  {/if}
</div>
