<script lang="ts">
  import { cn } from "$utils/ui";
  import type { HTMLAttributes } from "svelte/elements";

  type $$Props = HTMLAttributes<HTMLDivElement>;

  let className: $$Props["class"] = undefined;
  export { className as class };
</script>

<div class={cn("flex flex-col gap-4 text-center sm:text-left", className)} {...$$restProps}>
  <slot />
</div>
