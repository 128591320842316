<script lang="ts">
  import { cn } from "$utils/ui";
  import { Input } from "$ui/input";
  import { Button } from "$ui/button";
  import { pasteFromClipboard } from "$lib/utils/helpers";
  import { appKeyStore } from "$lib/utils/stores";

  export let app_id = "";
  export let keyIndex: number = 1;
  export let variant: "default" | "outline" = "outline";

  let errorMessage = "";

  const pasteApiKey = async () => {
    const apiKey = await pasteFromClipboard();
    appKeyStore.set(apiKey)
  };

</script>

<div class="relative">
  <Input
    type="text"
    class={cn(
      "w-0 p-0 !border-0 sm:!border sm:px-3 sm:w-full text-current sm:pr-[264px] focus:border-gray-300 font-medium",
      // $appKeyStore && "!cursor-copy",
    )}
    placeholder="Enter your API key"
    bind:value={$appKeyStore}
  />

  <Button
    class={cn(
      "absolute right-0 top-0",
      "rounded-l-none w-1/2 sm:w-auto min-w-[100px] rounded-r-md",
      "border border-l-0",
      variant === "outline" ? "bg-gray-100 dark:!border-primary-700 dark:bg-white/10" : "border-primary-700/70",
      "disabled:!cursor-not-allowed",
    )}
    on:click={pasteApiKey}
    {variant}
  >
      Paste API Key
  </Button>
</div>



{#if errorMessage}
  <div class="text-danger-400 text-sm">{errorMessage}</div>
{/if}
