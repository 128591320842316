<script lang="ts">
  import { Button as ButtonPrimitive } from "bits-ui";
  import { cn } from "$utils/ui";

  import { type VariantProps } from "tailwind-variants";
  import { buttonVariants } from ".";

  type Variant = VariantProps<typeof buttonVariants>["variant"];
  type Size = VariantProps<typeof buttonVariants>["size"];

  type Props = ButtonPrimitive.Props & {
    variant?: Variant;
    size?: Size;
  };

  type Events = ButtonPrimitive.Events;

  type $$Props = Props;
  type $$Events = Events;

  let className: $$Props["class"] = undefined;
  export let variant: $$Props["variant"] = "default";
  export let size: $$Props["size"] = "default";
  export let builders: $$Props["builders"] = [];
  export { className as class };
</script>

<ButtonPrimitive.Root
  {builders}
  class={cn(buttonVariants({ variant, size, className }))}
  {...$$restProps}
  on:click
  on:keydown
>
  <slot />
</ButtonPrimitive.Root>
