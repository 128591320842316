<script lang="ts">
  import { writable } from "svelte/store";
  import { SubmitButton } from "$ui/submit-button";
  import { Button } from "$ui/button";

  export let steps: { component: any; props?: any }[] = [];
  export let onFinish: () => void;

  let step = 0;

  export let wizardStep = step;
  $: wizardStep = step;

  const onNext = () => {
    step += 1;
  };

  const onBack = () => {
    step -= 1;
  };

  let isSubmitting = writable(false);
  let errors = writable({});
  let touched = writable({});

  let handleSubmit: () => void;

  function handleSuccess(values: any) {
    if (step >= steps.length - 1) {
      onFinish();
    } else {
      onNext();
    }

    wizardValues.set({
      ...$wizardValues,
      ...values,
    });
  }

  let wizardValues = writable({ values: {} });
</script>

<div class="flex-1 flex flex-col items-stretch gap-4 w-full">
  <div class="flex-1 pb-8">
    {#each steps as { component: StepComponent, props }, i (i)}
      {#if step === i}
        {#each Array(1) as _}
          <StepComponent
            {...props}
            onSuccess={handleSuccess}
            {...$wizardValues}
            standaloneForm={false}
            bind:handleSubmit
            bind:isSubmitting
            bind:errors
            bind:touched
          />
        {/each}
      {/if}
    {/each}
  </div>
  <div class="sticky bottom-0 flex flex-col gap-3 bg-background-light dark:bg-background-dark-600" id="wizard-buttons">
    <div class="w-full flex gap-3 justify-stretch">
    {#if $isSubmitting !== undefined}
      {#if step > 0}
      <Button on:click={onBack} class="w-full" variant="ghost" disabled={$isSubmitting}>
        Back
      </Button>
      {/if}
      <SubmitButton bind:handleSubmit bind:isSubmitting bind:errors bind:touched>
        {#if $isSubmitting}
          Submitting...
        {:else if step < steps.length - 1}
          Next
        {:else}
          Finish
        {/if}
      </SubmitButton>
    {:else}
        {#if step > 0}
        <Button on:click={onBack} class="w-full" variant="ghost">
          Back
        </Button>
        {/if}
        <Button on:click={() => handleSubmit()} class="w-full">
          {#if step < steps.length - 1}
            Next
          {:else}
            Finish
          {/if}
        </Button>
    {/if}
    </div>
    {#if steps[step].props?.allowSkip}
      <Button on:click={() => onNext()} variant="link" class="self-center">Skip</Button>
    {/if}
    {#if steps[step].props?.allowSkipToEnd}
      <Button on:click={() => onFinish()} variant="link" class="self-center">Skip & Finish</Button>
    {/if}
  </div>
</div>
