<!-- @ts-nocheck -->

<script lang="ts">
  import { Button } from "$ui/button";
  import { cn } from "$utils/ui";

  export let handleSubmit: () => void = () => {};
  export let isSubmitting: any;
  export let errors: any;
  export let touched: any;

  const hasErrors = (currentErrors: { [x: string]: string } = {}, currentTouched: { [x: string]: any }): boolean => {
    return Object.keys(currentErrors).some((key) => {
      if (Array.isArray(currentErrors[key])) {
        return Object.keys(currentErrors[key]).some((index) => {
          return hasErrors(currentErrors[key][index], currentTouched[key][index]);
        });
      } else {
        return (!currentTouched?.[key] && currentErrors?.[key] === "") || currentErrors?.[key] !== "";
      }
    });
  };
</script>

<Button
  type="submit"
  on:click={() => handleSubmit && handleSubmit()}
  disabled={$isSubmitting}
  class={cn("w-full", {
    "opacity-70": hasErrors($errors, $touched),
  })}
>
  <slot />
</Button>
