<script lang="ts">
  import { push } from "svelte-spa-router";

  import Shell from "$lib/Shell.svelte";
  import Wizard from "$lib/Wizard.svelte";

  import TeamForm from "$lib/TeamForm.svelte";
  import MembersForm from "$lib/MembersForm.svelte";
  import AppForm from "$lib/AppForm.svelte";
  import DataSourcesForm from "$lib/DataSourcesForm.svelte";
  import IntegrateFrontendStep from "$lib/IntegrateFrontendStep.svelte";
  import IntegrateBackendStep from "$lib/IntegrateBackendStep.svelte";
  import IntegrateInstallStep from "$lib/IntegrateInstallStep.svelte";

  import LogoGraphic from "$assets/logo-graphic.svelte";
  import { selectedTeamIdStore } from "$lib/utils/stores";
  import { get } from "svelte/store";

  let step = 0;
  $: step;

  const steps = [
    {
      title: "Create your team",
      subtitle: "Assign your team an alias and team ID",
      component: TeamForm,
    },
    {
      title: "Add members",
      subtitle: "Invite your team members",
      component: MembersForm,
      props: {
        allowSkip: true,
      },
    },
    {
      title: "Create your first app",
      subtitle: "Follow some step-by-step instructions to get you integrated with multiple apps",
      component: AppForm,
      props: {
        isEdit: false,
        allowSkipToEnd: true,
      },
    },
    {
      title: "Integrate data sources",
      subtitle: "Choose a product to integrate with by following step-by-step instructions.",
      component: DataSourcesForm,
      props: {
        allowSkipToEnd: true,
      },
    },
    {
      title: "Integrate data sources",
      subtitle: "Choose a product to integrate with by following step-by-step instructions.",
      component: IntegrateFrontendStep,
      props: {
        allowSkipToEnd: true,
      },
    },
    {
      title: "Integrate data sources",
      subtitle: "Choose a product to integrate with by following step-by-step instructions.",
      component: IntegrateInstallStep,
      props: {
        allowSkipToEnd: true,
      },
    },
    {
      title: "Integrate data sources",
      subtitle: "Choose a product to integrate with by following step-by-step instructions.",
      component: IntegrateBackendStep,
    },
  ];

  const onFinish = () => {
    const teamId = get(selectedTeamIdStore);
    push(`/${teamId}`);
  };
</script>

<Shell
  mainClass="overflow-auto max-h-[100svh] !pb-0 onboarding"
  class="flex flex-col items-center gap-8 md:gap-12"
  hasSidebar={false}
  hasBackground={true}
>
  <div class="mt-4 md:mt-12 flex flex-col items-center gap-4 md:gap-6">
    <div class="[&>svg]:h-7 md:[&>svg]:h-12">
      <LogoGraphic />
    </div>
    <div class="flex flex-col items-center gap-1 md:gap-2 text-center">
      <h1>{steps[step].title}</h1>
      <p>{steps[step].subtitle}</p>
    </div>
  </div>

  <div class="flex-1 flex flex-col w-full max-w-2xl">
    <Wizard {steps} {onFinish} bind:wizardStep={step} />
  </div>
</Shell>
