<script lang="ts">
  import { DropdownMenu as DropdownMenuPrimitive } from "bits-ui";
  import { cn } from "$utils/ui";

  type $$Props = DropdownMenuPrimitive.ItemProps & {
    inset?: boolean;
    Icon?: any;
  };
  type $$Events = DropdownMenuPrimitive.ItemEvents;

  let className: $$Props["class"] = undefined;
  export let inset: $$Props["inset"] = undefined;
  export { className as class };
  export let Icon: any = undefined;
</script>

<DropdownMenuPrimitive.Item
  class={cn(
    "popover-item relative flex items-center gap-2 text-gray-800 cursor-default select-none rounded-sm px-2 py-1.5 text-sm",
    "cursor-pointer",
    "outline-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
    "font-medium",
    inset && "pl-8",
    className,
  )}
  {...$$restProps}
  on:click
  on:keydown
  on:focusin
  on:focusout
  on:pointerdown
  on:pointerleave
  on:pointermove
>
  {#if Icon}
    <div class="[&>svg]:w-4 [&>svg]:h-4">
      <Icon />
    </div>
  {/if}
  <slot />
</DropdownMenuPrimitive.Item>
