<script lang="ts">
  import type { HTMLInputAttributes } from "svelte/elements";
  import { cn } from "$utils/ui";
  import { Field } from "$ui/field";

  type FormInputEvent<T extends Event = Event> = T & {
    currentTarget: EventTarget & HTMLInputElement;
  };

  type InputEvents = {
    blur: FormInputEvent<FocusEvent>;
    change: FormInputEvent<Event>;
    click: FormInputEvent<MouseEvent>;
    focus: FormInputEvent<FocusEvent>;
    focusin: FormInputEvent<FocusEvent>;
    focusout: FormInputEvent<FocusEvent>;
    keydown: FormInputEvent<KeyboardEvent>;
    keypress: FormInputEvent<KeyboardEvent>;
    keyup: FormInputEvent<KeyboardEvent>;
    mouseover: FormInputEvent<MouseEvent>;
    mouseenter: FormInputEvent<MouseEvent>;
    mouseleave: FormInputEvent<MouseEvent>;
    paste: FormInputEvent<ClipboardEvent>;
    input: FormInputEvent<InputEvent>;
  };

  type $$Props = HTMLInputAttributes | any;
  type $$Events = InputEvents;

  let className: $$Props["class"] = undefined;
  export let value: $$Props["value"] = undefined;
  export let label: $$Props["label"] = undefined;
  export let name: $$Props["name"] = undefined;
  export let error: $$Props["error"] = undefined;
  export let type: $$Props["type"] = "text";
  export { className as class };
</script>

<Field {label} {error} {type}>
  <input
    id={name}
    class={cn(
      "field-input flex h-10 w-full rounded-md px-3 py-2 text-sm disabled:cursor-not-allowed disabled:opacity-50 outline-none",
      "read-only:opacity-70 read-only:cursor-not-allowed",
      className,
    )}
    {...{ type }}
    bind:value
    on:blur
    on:change
    on:click
    on:focus
    on:focusin
    on:focusout
    on:keydown
    on:keypress
    on:keyup
    on:mouseover
    on:mouseenter
    on:mouseleave
    on:paste
    on:input
    {...$$restProps}
  />
</Field>
