<svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M2.00033 8.83337C2.46056 8.83337 2.83366 8.46028 2.83366 8.00004C2.83366 7.5398 2.46056 7.16671 2.00033 7.16671C1.54009 7.16671 1.16699 7.5398 1.16699 8.00004C1.16699 8.46028 1.54009 8.83337 2.00033 8.83337Z"
    stroke="#98A2B3"
    stroke-width="1.66667"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M2.00033 3.00004C2.46056 3.00004 2.83366 2.62694 2.83366 2.16671C2.83366 1.70647 2.46056 1.33337 2.00033 1.33337C1.54009 1.33337 1.16699 1.70647 1.16699 2.16671C1.16699 2.62694 1.54009 3.00004 2.00033 3.00004Z"
    stroke="#98A2B3"
    stroke-width="1.66667"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M2.00033 14.6667C2.46056 14.6667 2.83366 14.2936 2.83366 13.8334C2.83366 13.3731 2.46056 13 2.00033 13C1.54009 13 1.16699 13.3731 1.16699 13.8334C1.16699 14.2936 1.54009 14.6667 2.00033 14.6667Z"
    stroke="#98A2B3"
    stroke-width="1.66667"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
