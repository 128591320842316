<script lang="ts">
  import { Dialog as DialogPrimitive } from "bits-ui";
  import X from "lucide-svelte/icons/x";
  import * as Dialog from ".";
  import { cn, flyAndScale } from "$utils/ui";
  import { slide, fly } from "svelte/transition";

  type $$Props = DialogPrimitive.ContentProps;

  let className: $$Props["class"] = undefined;

  export let transition: $$Props["transition"] = fly;
  export let transitionConfig: $$Props["transitionConfig"] = {
    duration: 350,
    axis: "x",
    opacity: 1,
    x: "101%",
    y: 0,
  };
  export { className as class };
</script>

<Dialog.Portal>
  <Dialog.Overlay />
  <DialogPrimitive.Content
    {transition}
    {transitionConfig}
    class={cn(
      "popover border-general fixed left-[50%] top-[50%] z-50 grid w-[80%] max-w-lg translate-x-[-50%] translate-y-[-50%] border p-0 pb-6 shadow-lg sm:rounded-tl-[20px] md:w-full",
      "gap-0 !top-0 !right-0 !left-auto translate-x-0 translate-y-0 w-full md:max-w-[600px] h-[100svh] flex-1 flex flex-col",
      "[&>div]:gap-1",
      className,
    )}
    {...$$restProps}
  >
    <slot />
    <DialogPrimitive.Close
      class="absolute right-6 top-8 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
    >
      <X class="h-5 w-5" />
      <span class="sr-only">Close</span>
    </DialogPrimitive.Close>
  </DialogPrimitive.Content>
</Dialog.Portal>
