<script lang="ts">
  import { cn } from "$utils/ui";
  import type { HTMLAttributes } from "svelte/elements";

  type $$Props = HTMLAttributes<HTMLTableRowElement> & {
    "data-state"?: unknown;
  };

  let className: $$Props["class"] = undefined;
  export { className as class };
</script>

<tr
  class={cn(
    "border-b border-general transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted",
    "overflow-hidden w-full flex flex-col sm:table-row relative",
    className,
  )}
  {...$$restProps}
  on:click
  on:keydown
>
  <slot />
</tr>
