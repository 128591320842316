import { Dialog as DialogPrimitive } from "bits-ui";

const Root = DialogPrimitive.Root;
const Trigger = DialogPrimitive.Trigger;

import Title from "$ui/dialog/dialog-title.svelte";
import Portal from "$ui/dialog/dialog-portal.svelte";
import Footer from "$ui/dialog/dialog-footer.svelte";
import Header from "$ui/dialog/dialog-header.svelte";
import Overlay from "$ui/dialog/dialog-overlay.svelte";
import Description from "$ui/dialog/dialog-description.svelte";

import Content from "./drawer-content.svelte";

export {
  Root,
  Title,
  Portal,
  Footer,
  Header,
  Trigger,
  Overlay,
  Content,
  Description,
  //
  Root as Drawer,
  Title as DrawerTitle,
  Portal as DrawerPortal,
  Footer as DrawerFooter,
  Header as DrawerHeader,
  Trigger as DrawerTrigger,
  Overlay as DrawerOverlay,
  Content as DrawerContent,
  Description as DrawerDescription,
};
