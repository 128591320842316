import { toast } from "svelte-sonner";
import CheckCircle from "$assets/check-circle.svelte";

export function fuzzyMatch(source: string, target: string) {
  const sourceLower = source.toLowerCase().replace(/\s+/g, "");
  const targetLower = target.toLowerCase().replace(/\s+/g, "");
  let targetIdx = 0;
  let matchCount = 0;

  for (let i = 0; i < sourceLower.length && targetIdx < targetLower.length; i++) {
    if (sourceLower[i] === targetLower[targetIdx]) {
      matchCount++;
      targetIdx++;
    }
  }
  return matchCount >= targetLower.length;
}

export function isArrayEqual(a: string | any[], b: string | any[]) {
  if (a.length !== b.length) return false;
  const sortedA = [...a].sort();
  const sortedB = [...b].sort();

  for (let i = 0; i < sortedA.length; i++) {
    if (sortedA[i] !== sortedB[i]) return false;
  }

  return true;
}

export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function formatDateTime(modifiedSeconds: { seconds: any }, withTime = true) {
  const timestampInSeconds = modifiedSeconds.seconds;
  const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  if (!withTime) {
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  }

  const formattedDate = `${year}/${month}/${day} - ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export function formatDateToHumanReadable(timestamp: number) {
  const now = Math.floor(Date.now() / 1000);
  const seconds = now - timestamp;
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 45) {
    return `an hour ago`;
  } else if (interval > 1) {
    return `${interval} minutes ago`;
  }
  return `a few seconds ago`;
}

export function copyToClipboard(text: string, description: string = "") {
  navigator.clipboard.writeText(text);

  toast.success("Copied to clipboard", {
    description: description,
    classes: {
      toast: "!text-success",
      title: "ml-1 !text-black dark:!text-white",
      description: "ml-1 !text-gray-600 dark:!text-gray-300 !font-normal",
    },
    icon: CheckCircle,
    onDismiss: (t) => console.log(`Toast with id ${t.id} has been dismissed`),
  });
}

export async function pasteFromClipboard() {
  const text = await navigator.clipboard.readText();

  return text;
}

export function saveToast(title: string, description: string = "") {
  toast.success(title, {
    description: description,
    classes: {
      toast: "!text-success",
      title: "ml-1 !text-black dark:!text-white",
      description: "ml-1 !text-gray-600 dark:!text-gray-300 !font-normal",
    },
    icon: CheckCircle,
    onDismiss: (t) => console.log(`Toast with id ${t.id} has been dismissed`),
  });
}

export const applyTheme = (selectedMode: string) => {
  if (
    selectedMode === "light" ||
    (selectedMode === "system" && window.matchMedia && window.matchMedia("(prefers-color-scheme: light)").matches)
  ) {
    document.body.classList.remove("dark");
  } else if (
    selectedMode === "dark" ||
    (selectedMode === "system" && window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.body.classList.add("dark");
  }
};
