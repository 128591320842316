<script lang="ts">
  import Shell from "$lib/Shell.svelte";
  import { Form } from "$lib/ui/form";
  import { setUser } from "$lib/utils/resources";
  import { teamsStore, userStore } from "$lib/utils/stores";
  import { Input } from "$ui/input";
  import * as Select from "$ui/select";
  import * as yup from "yup";

  import { applyTheme, saveToast } from "$lib/utils/helpers";

  let selectedMode = localStorage.getItem("mode") || "system";

  const MODES = [
    { value: "system", label: "System (Default)" },
    { value: "light", label: "Light" },
    { value: "dark", label: "Dark" },
  ];

  let form: any;
  let validateField: any;
  let updateValidateField: any;

  let teams: any[] = [];
  $: teams = $teamsStore;

  let initialValues = {
    name: $userStore?.preferences?.name || $userStore?.displayName || "",
    primary_email: $userStore?.preferences?.primary_email || $userStore?.email || "",
    default_team: $userStore?.preferences?.default_team || ($teamsStore[0] as any)?.id || "",
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Display name is required"),
  });

  const onSubmit = async (values: any) => {
    await setUser({ name: values.name, default_team: values.default_team, primary_email: values.primary_email });
    saveToast("Settings Saved!", "Your preferences have been saved successfully.");
  };

  const changeMode = (mode: string) => {
    if (mode === "system") {
      localStorage.removeItem("mode");
    } else {
      localStorage.setItem("mode", mode);
    }
    applyTheme(mode);
  };
</script>

<Shell title="Settings" class="flex flex-col gap-8">
  <div class="flex flex-col lg:flex-row gap-8 lg:gap-20">
    <div class="lg:max-w-xs xl:max-w-sm flex flex-col gap-2">
      <div class="font-semibold text-lg">Profile Settings</div>
      <p>Set your preferences here, what we should call you, your default team, and your email address.</p>
    </div>
    <div class="flex-1 max-w-lg" id="settings-form">
      <Form bind:form {initialValues} {validationSchema} {onSubmit} bind:validateField bind:updateValidateField>
        {#if $form}
          <Input
            label="Display Name"
            name="name"
            bind:value={$form.name}
            on:input={() => {
              validateField("name");
              validateField("default_team");
            }}
          />

          <Input label="Email" name="primary_email" bind:value={$form.primary_email} readonly />

          <Select.Root
            selected={{
              value: teams.find((team) => team.id === $form?.default_team)?.id ?? "",
              label: teams.find((team) => team.id === $form?.default_team)?.alias ?? "",
            }}
            onSelectedChange={(v) => {
              validateField("default_team");
              validateField("name");
              v && ($form.default_team = v.value);
            }}
          >
            <Select.Trigger name="alias" label="Default Team">
              <Select.Value placeholder="Select a Team" />
            </Select.Trigger>
            <Select.Content class="max-h-[400px] overflow-auto">
              <Select.Group>
                {#each teams as team}
                  <Select.Item value={team.id} label={team.alias}>
                    <div class="flex-1 flex flex-col font-medium">
                      {team.alias}
                      <span class="text-xs p font-normal">
                        {team.id}
                      </span>
                    </div>
                  </Select.Item>
                {/each}
              </Select.Group>
            </Select.Content>
          </Select.Root>
        {/if}
      </Form>
    </div>
  </div>

  <div class="border-t border-general mt-6 lg:mt-12 pt-6 lg:pt-12 flex flex-col lg:flex-row gap-8 lg:gap-20">
    <div class="lg:max-w-xs xl:max-w-sm flex flex-col gap-2">
      <div class="font-semibold text-lg">Appearance</div>
      <p>Set the Appearance of the App: Tailor Your Visual Experience to Suit Your Style Preferences</p>
    </div>

    <div class="flex-1 max-w-lg">
      <Select.Root
        selected={MODES.find((mode) => mode.value === selectedMode)}
        onSelectedChange={(mode) => {
          if (mode) {
            changeMode(mode.value);
          }
        }}
      >
        <Select.Trigger name="alias" label="Dark/Light Mode">
          <Select.Value placeholder="Select a Mode" />
        </Select.Trigger>
        <Select.Content>
          <Select.Group>
            {#each MODES as mode}
              <Select.Item value={mode.value} label={mode.label}>
                {mode.label}
              </Select.Item>
            {/each}
          </Select.Group>
        </Select.Content>
      </Select.Root>
    </div>
  </div>
</Shell>
