<script lang="ts">
  import { replace } from "svelte-spa-router";
  import { getUser } from "$lib/utils/resources";
  import { teamsStore } from "$lib/utils/stores";

  import { onMount } from "svelte";
  import Shell from "$lib/Shell.svelte";
  import { LoadingSpinner } from "$ui/loading-spinner";

  onMount(async () => {
    const user = await getUser();

    if ($teamsStore.length === 0) {
      replace("/onboarding");
    } else if (user?.preferences?.default_team) {
      replace(`/${user?.preferences?.default_team}`);
    } else {
      const firstTeamId = ($teamsStore[0] as { id?: string })?.id;
      replace(`/${firstTeamId}`);
    }
  });
</script>

<Shell hasSidebar={false} class="flex items-center justify-center">
  <LoadingSpinner />
</Shell>
