<script lang="ts">
  import firebase from "firebase/compat/app";
  import "firebase/compat/auth";
  import { replace } from "svelte-spa-router";

  import { LoadingSpinner } from "$ui/loading-spinner";
  import { Button } from "$ui/button";

  import Logo from "$assets/logo.svelte";
  import LogoGraphic from "$assets/logo-graphic.svelte";
  import GoogleLogo from "$assets/google-logo.svelte";
  import MicrosoftLogo from "$assets/microsoft-logo.svelte";
  import Shell from "$lib/Shell.svelte";
  import { envStore } from "$lib/utils/stores";
  import { onMount } from "svelte";

  let isLoading = true;
  let errorMessage: string | null;

  let showSignInEmailLinkSent = false;
  let emailAddressValue: string | null;
  let supportedProviders = ["google.com", "microsoft.com"];

  const storageKeyEmail = "emailForSignIn";
  const storageKeyCredentials = "pendingCred";
  type StoredCredentials = firebase.auth.AuthProvider & firebase.auth.OAuthCredentialOptions;

  function onSuccess() {
    // Clear local storage
    window.localStorage.removeItem(storageKeyEmail);
    window.localStorage.removeItem(storageKeyCredentials);
    // Redirect
    replace("/");
  }

  function onError(error: Error) {
    console.error("Error signing in:", error);
    isLoading = false;
    errorMessage = error.message;
  }

  /**
   * Logic for rendering the sign-in page & managing authentication flows.
   *
   * On page mount, user credentials from a redirect-based sign-in flow are checked. If user is authenticated, they are
   * redirected. If unauthenticated, the URL is checked to if it is a sign-in email-link, otherwise the user is shown
   * the list of OAuth providers for sign-in.
   * If `auth/account-exists-with-different-credential` error occurs, the user is presented with their existing OAuth
   * provider, or emailed a sign-in email-link. If the error occurs while using a sign-in email-link, the OAuth provider
   * is linked with the user's account, the user is authenticated & redirected.
   * All other errors are displayed; see `onError()`.
   */
  onMount(async () => {
    try {
      const result = await firebase.auth().getRedirectResult();
      if (result?.user) {
        // User is authenticated
        onSuccess();
      } else if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        await handleSignInWithEmailLink();
      } else {
        isLoading = false;
      }
    } catch (error: any) {
      if (error.code === "auth/account-exists-with-different-credential") {
        await handleAccountExistsWithDifferentCredentialsError(error);
      } else {
        onError(error as Error);
      }
    }
  });

  async function handleSignInWithEmailLink() {
    const emailAddress = window.localStorage.getItem(storageKeyEmail);
    const storedCred = window.localStorage.getItem(storageKeyCredentials);
    if (storedCred && emailAddress) {
      const credentials: StoredCredentials = JSON.parse(storedCred);
      await signInWithEmailLink(emailAddress!, credentials);
    } else {
      // Require user to authenticated with a preferred OAuth provider
      isLoading = false;
    }
  }

  async function handleAccountExistsWithDifferentCredentialsError(error: any) {
    // Store auth details for account linking
    const [emailAddress, credentials] = credentialsFromError(error);
    window.localStorage.setItem(storageKeyEmail, emailAddress);
    window.localStorage.setItem(storageKeyCredentials, JSON.stringify(credentials));

    // Check if sign-in with email link is possible
    const isSignInWithEmailLink = firebase.auth().isSignInWithEmailLink(window.location.href);
    if (isSignInWithEmailLink) {
      return signInWithEmailLink(emailAddress, credentials);
    }

    // Check if error response lists known providers for user
    const verifiedProviders: string[] = error.customData._tokenResponse.verifiedProvider ?? []; // ["google.com", ...]
    const filteredProviders = verifiedProviders.filter((provider) => supportedProviders.includes(provider));
    if (verifiedProviders.length) {
      // Update UI with user's known provider
      supportedProviders = filteredProviders;
      errorMessage = "Account already exists with a different provider.";
      isLoading = false;
      return;
    }

    return sendSignInLinkToEmail(error);
  }

  /**
   * Sends a firebase sign-in link to email based on firebase `error` input.
   * This method is called in response to a `auth/account-exists-with-different-credential` error on successful sign-in
   * with OAuth provider. This error contains the user email to which the sign-in link will be emailed to. On success,
   * the UI state is updated to reflect that an email has been sent. All errors are displayed; see `onError()`.
   * @param error The untyped `auth/account-exists-with-different-credential` Firebase error
   */
  async function sendSignInLinkToEmail(error: any) {
    const [email, credentials] = credentialsFromError(error);
    // Email link authentication must be enabled in firebase:
    //  https://firebase.google.com/docs/auth/web/email-link-auth#enable_email_link_sign-in_for_your_firebase_project
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true, // must be true
    };
    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        // Store auth details for account linking
        window.localStorage.setItem(storageKeyEmail, email);
        window.localStorage.setItem(storageKeyCredentials, JSON.stringify(credentials));
        // Update UI directing user to sign in email link
        emailAddressValue = email;
        showSignInEmailLinkSent = true;
        isLoading = false;
      })
      .catch(onError);
  }

  /**
   * Returns email & OAuth credentials from a `auth/account-exists-with-different-credential` error.
   */
  function credentialsFromError(error: any): [string, StoredCredentials] {
    const { providerId, oauthIdToken, oauthAccessToken, pendingToken } = error.customData._tokenResponse;
    const provider = new firebase.auth.OAuthProvider(providerId);
    const credentials = provider.credential({ idToken: oauthIdToken, accessToken: oauthAccessToken, pendingToken }); // Note `pendingToken` is not typed
    const email = error.customData.email;
    return [email, credentials];
  }

  function signInWithRedirect(providerName: string) {
    isLoading = true;
    errorMessage = null;
    let provider;
    if (providerName === "google") {
      provider = new firebase.auth.GoogleAuthProvider();
    } else if (providerName === "microsoft") {
      provider = new firebase.auth.OAuthProvider("microsoft.com");
    }

    if (process.env.NODE_ENV === "development") {
      return firebase
        .auth()
        .signInWithPopup(provider as any)
        .then(() => onSuccess())
        .catch((error) => onError(error));
    } else {
      return firebase.auth().signInWithRedirect(provider as any);
    }
  }

  /**
   * Authenticates user with sign-in email link & links account to a preferred OAuth provider.
   * @param emailAddress The email address associated with the sign-in email link.
   * @param credentials The credentials of preferred OAuth provider
   */
  async function signInWithEmailLink(emailAddress: string, credentials: StoredCredentials) {
    await firebase
      .auth()
      .signInWithEmailLink(emailAddress, window.location.href)
      .then((result) => linkAccountsAndRedirect(result.user!, credentials))
      .catch(onError)
      // Remove sign-in tokens from URL params & redirect
      .then(() => history.replaceState(null, "", window.location.pathname));
  }

  async function linkAccountsAndRedirect(user: firebase.User, creds: StoredCredentials) {
    const provider = new firebase.auth.OAuthProvider(creds.providerId);
    const credentials = provider.credential(creds);
    return (
      user
        .linkWithCredential(credentials)
        // Unlink email provider in favour of third-party OAuth provider
        .then(() => user.unlink("password"))
        // Ignore error if provider does not exist for user or already linked
        .catch((error) => {
          if (!["auth/no-such-provider", "auth/provider-already-linked"].includes(error.code)) throw error;
        })
        // Remove sign-in tokens from URL params & redirect
        .then(() => history.replaceState(null, "", window.location.pathname))
        .then(onSuccess)
    );
  }

  const date = new Date();
  const year = date.getFullYear();

  let tosLink = $envStore?.legals?.termsOfService || "https://www.redactive.ai";
  let privacyLink = $envStore?.legals?.privacyPolicy || "https://www.redactive.ai/privacy";
</script>

<Shell hasSidebar={false} class="flex flex-col" hasBackground>
  <nav class="flex">
    <div class="flex justify-between items-center gap-4 p-8 max-w-7xl mx-auto">
      <div class="[&>svg]:h-6">
        <Logo />
      </div>
    </div>
  </nav>
  <main class="flex-[2.5] flex justify-center items-center">
    <div class="flex flex-col items-center gap-8 w-full max-w-[360px]">
      <div class="flex flex-col items-center gap-4">
        <div class="[&>svg]:w-12 [&>svg]:h-12">
          <LogoGraphic />
        </div>
        <div class="flex flex-col items-center gap-2">
          <h1 class="text-3xl font-semibold">Sign In</h1>
        </div>
      </div>
      <div class="sm:min-w-[360px] min-h-[120px] relative flex">
        {#if isLoading}
          <div class="flex-1 flex justify-center items-center">
            <LoadingSpinner />
          </div>
        {:else if showSignInEmailLinkSent}
          <div class="flex-1 flex justify-center items-center text-center">
            An email has been sent to your inbox<br />
            with a link to complete your sign-in.
            <br /><br />
            {emailAddressValue}
          </div>
        {:else}
          <div class="w-full flex flex-col gap-3">
            {#if supportedProviders.includes("google.com")}
              <Button
                variant="outline"
                size="lg"
                class="bg-white dark:bg-transparent [&>svg]:h-[22px] flex gap-3"
                on:click={() => signInWithRedirect("google")}
              >
                <GoogleLogo />
                Sign in with Google</Button
              >
            {/if}
            {#if supportedProviders.includes("microsoft.com")}
              <Button
                variant="outline"
                size="lg"
                on:click={() => signInWithRedirect("microsoft")}
                class="bg-white dark:bg-transparent [&>svg]:h-[22px] flex gap-3"
              >
                <MicrosoftLogo />
                Sign in with Microsoft</Button
              >
            {/if}
          </div>
        {/if}
      </div>

      <div class="text-center text-sm lg:px-12">
        By signing in you agree to our
        <a href={tosLink} target="_blank" rel="noreferrer" class="text-primary dark:text-primary-700 underline"
          >Terms of Service</a
        >
        and
        <a href={privacyLink} target="_blank" rel="noreferrer" class="text-primary dark:text-primary-700 underline"
          >Privacy Policy</a
        >
      </div>
      {#if errorMessage}
        <div class="text-danger-400 text-sm text-center">{errorMessage}</div>
      {/if}
    </div>
  </main>
  <footer class="flex-1 flex items-end">
    <div class="flex justify-center items-center gap-4 p-8 max-w-7xl mx-auto">
      <div class="flex items-center gap-4">
        <p class="text-sm text-center">© Redactive AI {year}. All rights reserved.</p>
      </div>
    </div>
  </footer>
</Shell>
