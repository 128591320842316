<script lang="ts">
  import Router, {
    location,
    replace,
    type ConditionsFailedEvent,
    type RouteLoadingEvent,
    type RouteDetail,
    type RouteLoadedEvent,
  } from "svelte-spa-router";
  import { wrap } from "svelte-spa-router/wrap";
  import { onMount, type SvelteComponent } from "svelte";
  import { get } from "svelte/store";

  import Login from "./routes/Login.svelte";
  import Home from "./routes/Home.svelte";
  import AuthRedirect from "./routes/AuthRedirect.svelte";

  import Dashboard from "./routes/Dashboard.svelte";
  import Apps from "./routes/Apps.svelte";
  import Members from "./routes/Members.svelte";

  import Usage from "./routes/Usage.svelte";
  import Settings from "./routes/Settings.svelte";
  import Onboarding from "./routes/Onboarding.svelte";
  import Team404 from "./routes/Team404.svelte";

  import { LoadingSpinner } from "$ui/loading-spinner";
  import { Toaster } from "$ui/sonner";
  import { AuthState, authState, selectedTeamIdStore, teamsStore } from "$utils/stores";
  import { initApp } from "$utils/bootstrap";
  import { getTeams } from "$utils/resources";

  import { applyTheme } from "$lib/utils/helpers";

  // Handles the "conditionsFailed" event dispatched by the router when a component can't be loaded because one of its pre-condition failed
  function conditionsFailed(event: ConditionsFailedEvent) {
    console.error("conditionsFailed event", event.detail);

    if (!(event.detail.userData as { authenticated: boolean }).authenticated && event.detail.route !== "/login") {
      replace(`/login`);
    }
  }

  // Handles the "routeLoaded" event dispatched by the router when a component was loaded
  function routeLoaded(event: RouteLoadedEvent) {
    console.log("routeLoaded event", event.detail);
  }

  // Handles the "routeLoaded" event dispatched by the router when a component was loaded
  function routeLoading(event: RouteLoadingEvent) {
    console.log("routeLoading event", event.detail);
  }

  function checkAuth(detail: RouteDetail) {
    const state = get(authState);
    const authenticated = state === AuthState.Authenticated;
    detail.userData = { authenticated };

    return authenticated;
  }

  async function checkTeamId(teams: any[]) {
    const teamId = $selectedTeamIdStore;
    const isValidTeamId = teams.some((team) => team.id === teamId);

    if (!isValidTeamId && !!teamId && teamId !== "" && $location !== "/team-404") {
      replace("/team-404");
      selectedTeamIdStore.set("");
      return false;
    } else {
      return true;
    }
  }

  async function getAndSetTeams(teamId: string | undefined = undefined): Promise<boolean> {
    const state = get(authState);
    const authenticated = state === AuthState.Authenticated;
    if (!authenticated) {
      return false;
    }

    return new Promise((resolve) => {
      getTeams((teams) => {
        // @ts-ignore
        teamsStore.set(teams);
        if (teamId !== undefined && teamId !== "" && !noneTeamRoutes.includes(teamId)) {
          checkTeamId(teams);
        }
        resolve(true);
      });
    });
  }

  let noneTeamRoutes = ["settings", "onboarding", "team-404"];
  let keepPreviousTeamRoutes = ["settings", "onboarding"];

  $: {
    const paths = $location.split("/");
    const teamIdInURL = paths[1];

    if (!noneTeamRoutes.includes(teamIdInURL)) {
      selectedTeamIdStore.set(teamIdInURL);
    } else {
      if (!keepPreviousTeamRoutes.includes(teamIdInURL)) {
        selectedTeamIdStore.set("");
      }
    }
  }

  export const routes = {
    "/login": Login,
    "/auth/redirect": AuthRedirect,
    "/settings": wrap({
      component: Settings as typeof SvelteComponent,
      conditions: [(detail) => checkAuth(detail)],
    }),
    "/onboarding": wrap({
      component: Onboarding as typeof SvelteComponent,
      conditions: [(detail) => checkAuth(detail)],
    }),
    "/team-404": wrap({
      component: Team404 as typeof SvelteComponent,
      conditions: [(detail) => checkAuth(detail)],
    }),

    "/": wrap({
      component: Home as typeof SvelteComponent,
      conditions: [(detail) => checkAuth(detail), () => getAndSetTeams()],
    }),
    "/:teamId": wrap({
      component: Dashboard as typeof SvelteComponent,
      conditions: [(detail) => checkAuth(detail), (detail) => getAndSetTeams(detail?.params?.teamId)],
    }),
    "/:teamId/apps": wrap({
      component: Apps as typeof SvelteComponent,
      conditions: [(detail) => checkAuth(detail), (detail) => getAndSetTeams(detail?.params?.teamId)],
    }),
    "/:teamId/members": wrap({
      component: Members as typeof SvelteComponent,
      conditions: [(detail) => checkAuth(detail), (detail) => getAndSetTeams(detail?.params?.teamId)],
    }),
    "/:teamId/usage": wrap({
      component: Usage as typeof SvelteComponent,
      conditions: [(detail) => checkAuth(detail), (detail) => getAndSetTeams(detail?.params?.teamId)],
    }),
    "*": Home,
  };

  const init = initApp().then(() => getAndSetTeams());

  let selectedMode = localStorage.getItem("mode") || "system";

  onMount(async () => {
    applyTheme(selectedMode);
  });
</script>

{#await init}
  <div class="flex-1 flex justify-center items-center bg-background-light dark:bg-background-dark">
    <LoadingSpinner />
  </div>
{:then result}
  <Router {routes} on:conditionsFailed={conditionsFailed} on:routeLoading={routeLoading} on:routeLoaded={routeLoaded} />
  <Toaster offset="10px" />
{/await}
