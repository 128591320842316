<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12.9199 6.27283H6.91988C6.1835 6.27283 5.58655 6.86978 5.58655 7.60616V13.6062C5.58655 14.3425 6.1835 14.9395 6.91988 14.9395H12.9199C13.6563 14.9395 14.2532 14.3425 14.2532 13.6062V7.60616C14.2532 6.86978 13.6563 6.27283 12.9199 6.27283Z"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
  />
  <path
    d="M3 10.6667H2.33333C1.97971 10.6667 1.64057 10.5262 1.39052 10.2761C1.14048 10.0261 1 9.68696 1 9.33333V3.33333C1 2.97971 1.14048 2.64057 1.39052 2.39052C1.64057 2.14048 1.97971 2 2.33333 2H8.33333C8.68696 2 9.02609 2.14048 9.27614 2.39052C9.52619 2.64057 9.66667 2.97971 9.66667 3.33333V4"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
  />
</svg>
